import styled from "styled-components";

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  margin: auto;
  flex-direction: column;

  @keyframes ldio-vt2ko44y9lk {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  .ldio-vt2ko44y9lk div {
    position: absolute;
    width: 120px;
    height: 120px;
    border: 20px solid #f8cb00;
    border-top-color: transparent;
    border-radius: 50%;
  }
  .ldio-vt2ko44y9lk div {
    animation: ldio-vt2ko44y9lk 1s linear infinite;
    top: 100px;
    left: 100px;
  }
  .loadingio-spinner-rolling-mnfz8gk0f08 {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: transparent;
  }
  .ldio-vt2ko44y9lk {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
  }
  .ldio-vt2ko44y9lk div {
    box-sizing: content-box;
  }
`;
