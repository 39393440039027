import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import {
  lightTheme,
  MeetingProvider,
  NotificationProvider,
  darkTheme,
  GlobalStyles,
} from "amazon-chime-sdk-component-library-react";

import { AppStateProvider, useAppState } from "./providers/AppStateProvider";
import ErrorProvider from "./providers/ErrorProvider";
import routes from "./constants/routes";
import { NavigationProvider } from "./providers/NavigationProvider";
import { Meeting, Home, DeviceSetup } from "./views";
import Notifications from "./containers/Notifications";
import meetingConfig from "./meetingConfig";
import Join from "./views/Join";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { AuthProvider } from "./providers/AuthProvider";
import MeetingEventHandler from "./containers/MeetingEventHandler";
import Error from "./views/Error";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const App = () => (
  <Router>
    <AppStateProvider>
      <AuthProvider>
        <Theme>
          <NotificationProvider>
            <Notifications />
            <ErrorProvider>
              <MeetingProvider {...meetingConfig}>
                <MeetingEventHandler>
                  <NavigationProvider>
                    <Switch>
                      <Route exact path={routes.JOIN} component={Join} />
                      <Route exact path={routes.HOME} component={Home} />
                      <Route path={routes.DEVICE}>
                        <DeviceSetup />
                      </Route>
                      <Route path={routes.MEETING}>
                        <Meeting />
                      </Route>
                      <Route path={routes.ERROR} component={Error} />
                    </Switch>
                  </NavigationProvider>
                </MeetingEventHandler>
              </MeetingProvider>
            </ErrorProvider>
          </NotificationProvider>
        </Theme>
      </AuthProvider>
    </AppStateProvider>
  </Router>
);

const Theme = ({ children }) => {
  const { theme } = useAppState();

  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};

export default App;
