// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect, useState } from "react";
import {
  UserActivityProvider,
  RemoteVideos,
  ContentShare,
  LocalVideo,
  useContentShareState,
  Flex,
  useMeetingManager,
  useRemoteVideoTileState,
  RemoteVideo,
  useRosterState,
  VideoGrid,
  useNotificationDispatch,
  Severity,
  ActionType,
  LoggerProvider,
  useAttendeeStatus,
} from "amazon-chime-sdk-component-library-react";

import {
  Main,
  StyledLayout,
  StyledContent,
  VideoItem,
  HeaderSection,
} from "./Styled";
import NavigationControl from "../../containers/Navigation/NavigationControl";
import { useNavigation } from "../../providers/NavigationProvider";
import MeetingHeader from "../../containers/MeetingHeader";
import MeetingControls from "../../containers/MeetingControls";
import useMeetingEndRedirect from "../../hooks/useMeetingEndRedirect";
import MeetingMetrics from "../../containers/MeetingMetrics";
import { useAppState } from "../../providers/AppStateProvider";
import { MessagingProvider } from "../../providers/ChatMessagesProvider";
import { LogLevel, POSTLogger, ConsoleLogger } from "amazon-chime-sdk-js";
import MeetingFooter from "../../containers/MeetingFooter/index";
import OffScreen from "../../containers/OffScreen";
import IconCircle from "../../components/Common/IconCircle";
import CenterContainer from "../../components/Common/Container/center";
import Button from "../../components/Common/Button";
import { ATTENDEE_ROLE, CLIENT_URL } from "../../constants";
import {
  CameraIcon,
  MicrophoneIcon,
  PresentationIcon,
} from "../../components/Common/Icon";

const MeetingView = () => {
  useMeetingEndRedirect();
  const {
    showNavbar,
    showRoster,
    openRoster,
    closeRoster,
    showChat,
    closeChat,
  } = useNavigation();

  const { sharingAttendeeId } = useContentShareState();
  const { meetingId, localAttendeeId, courseName, role } = useAppState();
  const { signalStrength, videoEnabled } = useAttendeeStatus(localAttendeeId);

  const logger = new ConsoleLogger("DDN", "debug");

  const logger1 = new POSTLogger({
    url: `${process.env.REACT_APP_CHIME_BACKEND_URL}/log`,
    batchSize: 100,
    logLevel: LogLevel.INFO,
    metadata: {
      meetingId,
      localAttendeeId,
    },
  });

  const manager = useMeetingManager();
  const dispatch = useNotificationDispatch();
  const { roster } = useRosterState();
  let attendees = Object.values(roster);
  const hosts = [];
  attendees.map((attendee) => attendee.role === 1 && hosts.push(attendee));

  useEffect(() => {
    manager.meetingSession.audioVideo.realtimeSubscribeToReceiveDataMessage(
      `management_${meetingId}`,
      (message) => {
        const string = new TextDecoder().decode(message.data);
        const obj = JSON.parse(string);
        if (obj.muteAll) {
          manager.audioVideo.realtimeMuteLocalAudio();
        }

        if (obj.raiseHand) {
          const attendee = roster[obj.attendeeId];
          const payload = {
            severity: Severity.WARNING,
            message: `${obj.userName} raised their hand.`,
            autoClose: true,
            autoCloseDelay: 5000,
          };

          dispatch({
            type: ActionType.ADD,
            payload,
          });
        }
      }
    );
  }, []);

  useEffect(() => {
    if (sharingAttendeeId) {
      closeRoster();
      closeChat();
    } else {
      openRoster();
    }
  }, [sharingAttendeeId]);

  const { tiles, tileIdToAttendeeId } = useRemoteVideoTileState();

  return (
    <UserActivityProvider>
      <MessagingProvider>
        <MeetingHeader />
        <Main>
          <HeaderSection>
            <div className="container">
              <div className="pageHeader">
                <div className="content">
                  <div className="supportText">
                    <div className="text">LIVE:</div>
                    <div className="text-1">{courseName}</div>
                  </div>
                </div>
              </div>
            </div>
          </HeaderSection>

          <StyledLayout showRoster={showRoster} showChat={showChat}>
            <StyledContent>
              <LoggerProvider logger={logger}>
                <MeetingMetrics />
              </LoggerProvider>
              {signalStrength < 0.4 ? (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100vw",
                  }}
                >
                  <p style={{ color: "red" }}>Your signal is weak.</p>
                </div>
              ) : null}
              {sharingAttendeeId ? (
                <>
                  <div className="flex-1 flex flex-row">
                    <ContentShare
                      className="flex-1 w-full"
                      nameplate={
                        attendees.find(
                          (i) => i.chimeAttendeeId === sharingAttendeeId
                        )
                          ? attendees.find(
                              (i) => i.chimeAttendeeId === sharingAttendeeId
                            ).name
                          : ""
                      }
                    />
                    <Flex
                      css="height: 95vh; width: 180px; overflow: hidden; overflow-y: auto; background: #697586;"
                      flexDirection="column"
                    >
                      <LocalVideo
                        className="mini-video"
                        css="object-fit: contain !important; width: 180px !important; height: 120px;"
                        nameplate={"Me"}
                      />
                      <RemoteVideos
                        className="mini-video"
                        css="object-fit: contain !important; width: 180px !important; height: 120px;"
                      />
                    </Flex>
                  </div>
                  <MeetingControls />
                </>
              ) : (
                <>
                  <VideoGrid className="videoGrid">
                    <OffScreen />
                    <MeetingControls />
                  </VideoGrid>
                </>
              )}
              <NavigationControl />
            </StyledContent>
          </StyledLayout>
        </Main>
        <MeetingFooter />
      </MessagingProvider>
    </UserActivityProvider>
  );
};

export default MeetingView;
