import AWS from "aws-sdk";
import { getCreds } from "../utils/api";

export default class DDNChatAwsCredentials extends AWS.Credentials {
  constructor(options, token, meetingId) {
    super(options);
    this.token = token;
    this.meetingId = meetingId;
    this.refreshNeeded = false;
    this.timeoutId = setTimeout(() => {
      this.refreshNeeded = true;
    }, 1000 * 60 * 14);
    this.intervalId = setInterval(() => {
      this.get();
    }, 1000 * 5);
  }

  refresh(callback) {
    console.log("refreshing AWS credentials");
    getCreds(this.meetingId, this.token).then((creds) => {
      callback(null, {
        accessKeyId: creds.ChimeCredentials.AccessKeyId,
        secretAccessKey: creds.ChimeCredentials.SecretAccessKey,
        sessionToken: creds.ChimeCredentials.SessionToken,
      });
      this.refreshNeeded = false;
      this.timeoutId = setTimeout(() => {
        this.refreshNeeded = true;
      }, 1000 * 60 * 15);
    });
  }

  needsRefresh() {
    return this.refreshNeeded;
  }
}
