// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  MeetingStatus,
  useMeetingStatus,
} from "amazon-chime-sdk-component-library-react";
import routes from "../constants/routes";

const useMeetingEndRedirect = () => {
  const history = useHistory();
  const meetingStatus = useMeetingStatus();

  useEffect(() => {
    if (meetingStatus === MeetingStatus.Ended) {
      console.log("[useMeetingEndRedirect] Meeting ended");
      if (window.location.hostname.indexOf("-qa") > -1) {
        window.location.href = "https://qa.dingdongnow.com/meeting-end";
      } else {
        window.location.href = "https://dingdongnow.com/meeting-end";
      }

      history.push(routes.HOME);
    }
  }, [meetingStatus]);
};

export default useMeetingEndRedirect;
