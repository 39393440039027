// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect } from "react";
import {
  useLogger,
  useMediaStreamMetrics,
} from "amazon-chime-sdk-component-library-react";

import { StyledMetrics } from "./Styled";
import { useNavigation } from "../../providers/NavigationProvider";

function formatMetric(metric) {
  return metric ? `${metric} Kbps` : null;
}

const MeetingMetrics = () => {
  const { showMetrics } = useNavigation();

  return <BandwidthMetrics show={showMetrics} />;
};

const BandwidthMetrics = ({ show }) => {
  const { availableIncomingBandwidth, availableOutgoingBandwidth } =
    useMediaStreamMetrics();

  const logger = useLogger();

  useEffect(() => {
    logger.info(
      JSON.stringify({
        bandwidthMetrics: {
          availableIncomingBandwidth,
          availableOutgoingBandwidth,
        },
      })
    );
  }, [availableIncomingBandwidth, availableOutgoingBandwidth, logger]);

  return (
    <StyledMetrics show={show}>
      <p className="metric title">Bandwidth</p>
      <p className="metric">
        Incoming: {formatMetric(availableIncomingBandwidth) || "unavailable"}
      </p>
      <p className="metric">
        Outgoing: {formatMetric(availableOutgoingBandwidth) || "unavailable"}
      </p>
    </StyledMetrics>
  );
};

export default MeetingMetrics;
