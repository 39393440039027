// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";

import MeetingRoster from "../MeetingRooster/MeetingRoster";
import MeetingChat from "../Chat";
import Navigation from ".";
import { useNavigation } from "../../providers/NavigationProvider";
import { Flex } from "amazon-chime-sdk-component-library-react";

const NavigationControl = () => {
  const { showNavbar, showChat, showRoster } = useNavigation();

  return (
    <>
      {showChat || showRoster ? (
        <Flex flexDirection="column">
          <MeetingRoster showRoster={showRoster} />
          {showChat ? <MeetingChat /> : null}
        </Flex>
      ) : null}
    </>
  );
};

export default NavigationControl;
