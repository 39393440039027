import MeetingFooter from "../../containers/MeetingFooter";
import MeetingHeader from "../../containers/MeetingHeader";
import { HeaderSection, Main } from "../Meeting/Styled";

export default function Error() {
  return (
    <>
      <MeetingHeader />
      <Main>
        <HeaderSection>
          <div className="container">
            <div className="pageHeader">
              <div className="content">
                <div className="supportText">
                  <div className="text">Error:</div>
                  <div className="text-1">Meeting has ended.</div>
                </div>
              </div>
            </div>
          </div>
        </HeaderSection>
        <div className="container">
          <div className="w-full min-h-[150px] text-center">
            <p>
              This meeting has ended. Please go to "Events" and find other
              events.
            </p>
          </div>
        </div>
      </Main>
      <MeetingFooter />
    </>
  );
}
