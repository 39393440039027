// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";

import logo from "../../containers/MeetingControls/logo";
import { StyledLayout } from "./Styled";

const Home = () => (
  <StyledLayout>
    <div>
      <svg
        id="logo"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="895.622"
        height="134.287"
        viewBox="0 0 895.622 134.287"
        style={{ transform: "scale(.8)" }}
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            y1="0.5"
            x2="1.004"
            y2="0.5"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#f63" />
            <stop offset="1" stopColor="#ffb900" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1="1.002"
            y1="0.5"
            x2="-0.002"
            y2="0.5"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#fff" />
            <stop offset="1" stopColor="#231f20" />
          </linearGradient>
          <radialGradient
            id="radial-gradient"
            cx="0.5"
            cy="0.485"
            r="5.628"
            gradientTransform="translate(0.456) scale(0.089 1)"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#a3a3a3" />
            <stop offset="1" stopColor="#fff" stopOpacity={0} />
          </radialGradient>
          <linearGradient
            id="linear-gradient-3"
            x1="0.409"
            y1="-1.528"
            x2="0.842"
            y2="8.154"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-4"
            x1="0.425"
            y1="-1.527"
            x2="0.775"
            y2="7.939"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-5"
            x1="0.459"
            y1="-1.626"
            x2="0.704"
            y2="8.055"
            xlinkHref="#linear-gradient"
          />
        </defs>
        <g id="Group" transform="translate(35.373)">
          <path
            id="Path"
            d="M37.224,0A58.605,58.605,0,0,0,6,8.953c14.411,2.184,29.041,4.8,43.233,6.987,3.275.655,4.367,1.31,4.367,2.839,0,12.009.218,24.237,0,36.246-.655,14.629.655,29.7,0,44.325,0,2.184-2.62,3.494-6.332,3.93-13.319,2.184-26.857,4.149-40.176,6.114a61.529,61.529,0,0,0,29.914,8.3A58.987,58.987,0,0,0,95.96,58.737,58.339,58.339,0,0,0,37.224,0Z"
            transform="translate(6.883 0.001)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Shape"
            d="M49.447,87.2c.218-12.446-.218-25.11,0-37.556.218-10.262,0-20.743,0-31.006a2.555,2.555,0,0,0-2.184-2.62c-10.918-2.62-21.4-5.459-32.1-7.861C11.891,7.284,8.4,6.628,5.34,5.973H3.375V5.755A14.5,14.5,0,0,1,.1,5.1v94.982a22.154,22.154,0,0,1,3.93.873A6.047,6.047,0,0,0,6,100.737c4.8-1.31,9.171-2.62,13.974-3.494,8.516-2.184,17.468-4.149,26.2-6.332C48.137,90.257,49.447,89.165,49.447,87.2ZM43.333,55.1a4.3,4.3,0,0,1-4.8-3.93,4.607,4.607,0,0,1,4.8-3.93,4.3,4.3,0,0,1,4.8,3.93,3.007,3.007,0,0,1-1.965,2.839v.655a1.31,1.31,0,0,1-.873-.218,3.057,3.057,0,0,1-1.965.655Z"
            transform="translate(-0.1 6.037)"
            fill="url(#linear-gradient-2)"
          />
        </g>
        <ellipse
          id="Oval"
          cx="83.41"
          cy="7.424"
          rx="83.41"
          ry="7.424"
          transform="translate(0 119.439)"
          fill="url(#radial-gradient)"
        />
        <g
          id="Group_4"
          data-name="Group 4"
          transform="translate(-1981.921 -2565)"
        >
          <path
            id="Path_1"
            data-name="Path 1"
            d="M2608.5,1312.582h26.314c20.07,0,33.718,12.667,33.718,31.22s-13.648,31.223-33.718,31.223H2608.5Zm25.779,54.679c15.431,0,25.332-9.455,25.332-23.459s-9.9-23.457-25.332-23.457h-16.858v46.918Z"
            transform="translate(-447.724 1284.354)"
            fill="#fff"
          />
          <path
            id="Path_2"
            data-name="Path 2"
            d="M2748.909,1312.582h8.921v62.441h-8.921Z"
            transform="translate(-514.453 1284.354)"
            fill="#fff"
          />
          <path
            id="Path_3"
            data-name="Path 3"
            d="M2855.3,1312.582v62.441h-7.312l-37.464-46.562v46.563h-8.921v-62.441h7.313l37.464,46.563v-46.563Z"
            transform="translate(-539.497 1284.354)"
            fill="#fff"
          />
          <path
            id="Path_4"
            data-name="Path 4"
            d="M2978.288,1342.8h8.563v24.349c-6.244,5.264-14.9,7.939-23.637,7.939-19.179,0-33.273-13.469-33.273-31.934s14.094-31.933,33.451-31.933c9.9,0,18.286,3.3,24,9.633l-5.531,5.53a24.751,24.751,0,0,0-18.107-7.224c-14.45,0-24.887,10.079-24.887,23.993,0,13.739,10.437,23.994,24.8,23.994a26.429,26.429,0,0,0,14.628-4.013Z"
            transform="translate(-600.488 1285)"
            fill="#fff"
          />
          <path
            id="Path_5"
            data-name="Path 5"
            d="M3073.749,1312.582h24.707c19.8,0,33.183,13.024,33.183,31.22s-13.381,31.223-33.183,31.223h-24.707Zm24.349,58.336c17.662,0,28.989-11.239,28.989-27.116s-11.327-27.115-28.989-27.115h-19.8v54.232Z"
            transform="translate(-668.832 1284.354)"
            fill="#fff"
          />
          <path
            id="Path_6"
            data-name="Path 6"
            d="M3310.121,1312.581v62.441h-3.746l-42.547-54.234v54.234h-4.549v-62.441h3.835l42.461,54.235v-54.235Z"
            transform="translate(-757.005 1284.354)"
            fill="#fff"
          />
          <path
            id="Path_7"
            data-name="Path 7"
            d="M3436.833,1343.667h4.37v23.371c-5.619,5.352-13.736,8.029-22.3,8.029-18.822,0-32.647-13.469-32.647-31.666s13.825-31.666,32.737-31.666c9.01,0,16.95,2.853,22.478,8.562l-2.853,3.034c-5.441-5.352-11.953-7.4-19.536-7.4-16.234,0-28.276,11.777-28.276,27.474,0,15.611,12.042,27.474,28.276,27.474,6.6,0,12.667-1.607,17.752-5.709Z"
            transform="translate(-817.349 1284.756)"
            fill="#fff"
          />
          <path
            id="Path_8"
            data-name="Path 8"
            d="M3582.917,1312.581v62.441H3575.6l-37.463-46.562v46.563h-8.921v-62.441h7.316L3574,1359.144v-46.563Z"
            transform="translate(-885.292 1284.354)"
            fill="url(#linear-gradient-3)"
          />
          <path
            id="Path_9"
            data-name="Path 9"
            d="M3657.556,1343.156c0-18.285,14.1-31.933,33.272-31.933,19,0,33.1,13.559,33.1,31.933s-14.095,31.933-33.1,31.933C3671.651,1375.09,3657.556,1361.441,3657.556,1343.156Zm57.446,0c0-13.826-10.349-23.994-24.175-23.994-14,0-24.351,10.167-24.351,23.994s10.346,23.994,24.351,23.994c13.826,0,24.175-10.169,24.175-23.994Z"
            transform="translate(-946.285 1285)"
            fill="url(#linear-gradient-4)"
          />
          <path
            id="Path_10"
            data-name="Path 10"
            d="M3888.619,1312.581l-20.874,62.441h-9.366l-17.038-49.864-17.037,49.864h-9.545l-20.87-62.441h9.189l16.858,50.755,17.573-50.755h8.207l17.212,51.023,17.216-51.023Z"
            transform="translate(-1011.076 1284.354)"
            fill="url(#linear-gradient-5)"
          />
          <path
            id="Path_13"
            data-name="Path 13"
            d="M3212.154,1312.582h4.487v62.441h-4.487Z"
            transform="translate(-734.609 1284.354)"
            fill="#fff"
          />
        </g>
      </svg>
    </div>
  </StyledLayout>
);

export default Home;
