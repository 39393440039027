import React from 'react'
import ddnLogoDark from '../../assets/images/ddn_logo_dark.svg'
import { CLIENT_URL } from '../../constants'
import MobileMenu from './MobileMenu'
import { MyFeed } from './Styled'

export default function MeetingHeader() {
  return (
    <>
      <div className="relative z-30 px-40 xl:px-20 lg:px-10 md:px-4 py-5 whitespace-nowrap text-white">
        <header className="my-6 lg:my-3 md:my-0 flex justify-between">
          <div className="flex">
            <div className="flex items-center flex-shrink-0 mr-6 md:mr-0">
              <a target="_blank" rel="noreferrer" href={`${CLIENT_URL}`}>
                <img
                  src={ddnLogoDark}
                  alt="DDN Logo"
                  style={{ width: '200px' }}
                />
              </a>
            </div>

            <ul className="flex items-center lg:hidden text-dgray-500 font-medium">
              <li className="ml-8">
                <a target="_blank" rel="noreferrer" href={`${CLIENT_URL}`}>
                  Overview
                </a>
              </li>
              <li className="ml-8">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${CLIENT_URL}events`}
                >
                  Events
                </a>
              </li>
              <li className="ml-8">
                <a target="_blank" rel="noreferrer" href={`${CLIENT_URL}hosts`}>
                  Hosts
                </a>
              </li>
              <li className="ml-8">
                <a target="_blank" rel="noreferrer" href={`${CLIENT_URL}blogs`}>
                  DDN Blog
                </a>
              </li>
            </ul>
          </div>
          <MenuAuth />
          <MobileMenu />
        </header>
      </div>
    </>
  )
}

function MenuAuth() {
  return (
    <ul className="flex items-center lg:hidden text-white">
      <div className="mr-8">
        <svg
          width="16"
          height="20"
          viewBox="0 0 16 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5003 15.8334C10.5003 17.2141 9.38097 18.3334 8.00026 18.3334C6.61954 18.3334 5.50026 17.2141 5.50026 15.8334M9.49734 5.19888C9.86025 4.82395 10.0836 4.3131 10.0836 3.75008C10.0836 2.59949 9.15085 1.66675 8.00026 1.66675C6.84966 1.66675 5.91692 2.59949 5.91692 3.75008C5.91692 4.3131 6.14026 4.82395 6.50317 5.19888M13.0003 9.33341C13.0003 8.18414 12.4735 7.08194 11.5358 6.26929C10.5981 5.45663 9.32634 5.00008 8.00026 5.00008C6.67417 5.00008 5.4024 5.45663 4.46472 6.26929C3.52704 7.08194 3.00026 8.18414 3.00026 9.33341C3.00026 11.2349 2.5287 12.6256 1.94031 13.6206C1.2697 14.7548 0.934402 15.3218 0.947643 15.4573C0.962792 15.6123 0.990685 15.6612 1.11637 15.7531C1.22623 15.8334 1.77818 15.8334 2.88208 15.8334H13.1184C14.2223 15.8334 14.7743 15.8334 14.8841 15.7531C15.0098 15.6612 15.0377 15.6123 15.0529 15.4573C15.0661 15.3218 14.7308 14.7548 14.0602 13.6206C13.4718 12.6256 13.0003 11.2349 13.0003 9.33341Z"
            stroke="#C93803"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <MyFeed>
        <a
          href={`${CLIENT_URL}feed`}
          target="_blank"
          rel="noreferrer"
          className="font-medium mr-8"
        >
          My Feed
        </a>
      </MyFeed>

      <a
        className="bg-primary-600 text-white px-5 py-3 rounded-lg whitespace-nowrap"
        href={`${CLIENT_URL}dashboard`}
        rel="noreferrer"
        target="_blank"
      >
        My DDN
      </a>
    </ul>
  )
}
