import {
  LocalVideo,
  RemoteVideo,
  useAttendeeStatus,
  useRemoteVideoTileState,
  useRosterState,
} from "amazon-chime-sdk-component-library-react";
import { useState } from "react";
import { ReactComponent as ChevronLeft } from "../../assets/images/icons/chevron-left.svg";
import {
  MicrophoneOffSmallIcon,
  MicrophoneSmallIcon,
  UnsubscribedBellIcon,
} from "../../components/Common/Icon";
import { ATTENDEE_ROLE, COURSE_TYPE } from "../../constants";
import { useAppState } from "../../providers/AppStateProvider";
import { useNavigation } from "../../providers/NavigationProvider";

export default function OffScreen() {
  const { roster } = useRosterState();
  let attendees = Object.values(roster);
  const { localAttendeeId, courseType } = useAppState();
  const { videoEnabled } = useAttendeeStatus(localAttendeeId);
  const { tiles, tileIdToAttendeeId, attendeeIdToTileId } =
    useRemoteVideoTileState();

  const hosts = attendees.filter((att) => att.role === ATTENDEE_ROLE.HOST);
  let speakers = attendees.filter((att) => att.role === ATTENDEE_ROLE.SPEAKER);
  if (courseType === 1) {
    speakers = [
      ...hosts,
      ...attendees.filter((att) => att.role !== ATTENDEE_ROLE.HOST),
    ];
  } else {
    speakers = [...hosts, ...speakers];
  }

  const { showChat, showRoster } = useNavigation();
  const isSidebarOpen = showChat || showRoster;

  const attendeeOneOnOne = attendees.find(
    (att) => att.role !== ATTENDEE_ROLE.HOST
  );
  const { videoEnabled: videoEnabledAttendeeOneOnOne } = useAttendeeStatus(
    attendeeOneOnOne?.chimeAttendeeId
  );

  return (
    <>
      <div
        className={`relative w-full h-full
          ${
            courseType === COURSE_TYPE.ONE_ON_ONE &&
            videoEnabledAttendeeOneOnOne
              ? // && speakers.length === 0
                "p-0"
              : isSidebarOpen
              ? "sm:p-0 px-8"
              : "sm:p-0 px-8"
          }
        `}
      >
        {speakers.length > 0 && (
          <SpeakerGrid
            speakers={speakers}
            attendeeIdToTileId={attendeeIdToTileId}
          />
        )}
      </div>
    </>
  );
}

function HostAttendeeBox({
  attendee,
  attendeeIdToTileId,
  videoEnabled: videoEnabledOuter,
  speakers,
  isSpeaker,
}) {
  const { localAttendeeId } = useAppState();
  const { videoEnabled } = useAttendeeStatus(attendee?.chimeAttendeeId);

  const videoEnabledInner = videoEnabled || videoEnabledOuter;

  return (
    <div className="flex-1 h-full">
      {videoEnabledInner ? (
        <div className="h-full relative">
          <div
            className={`overflow-hidden
              ${
                speakers?.length > 0 || !isSpeaker
                  ? "h-[700px] border-orange-400"
                  : "border-transparent"
              }
              ${isSpeaker ? "h-[700px]" : ""} 
            `}
          >
            {attendee.chimeAttendeeId === localAttendeeId ? (
              <LocalVideo />
            ) : (
              <RemoteVideo
                attendeeId={attendee.chimeAttendeeId}
                // name={roster[tileIdToAttendeeId[tileId]]?.name}
                tileId={attendeeIdToTileId[attendee.chimeAttendeeId]}
              />
            )}
          </div>
          <MentorBadge attendee={attendee} videoEnabled={videoEnabledInner} />
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center">
          <div
            className={`flex flex-col py-20 lg:py-16 md:py-10 w-full justify-center items-center border-2 border-solid rounded-xl 
              ${
                speakers?.length > 0 && !isSpeaker
                  ? "border-orange-400"
                  : "border-transparent"
              }
            `}
          >
            <MentorAvatar attendee={attendee} />
            <MentorBadge
              attendee={attendee}
              videoEnabled={videoEnabledInner}
              isSpeaker={isSpeaker}
            />
          </div>
        </div>
      )}
    </div>
  );
}

function SpeakerGrid({ speakers, attendeeIdToTileId }) {
  const { showChat, showRoster } = useNavigation();
  const isSidebarOpen = showChat || showRoster;
  const maxAttendeePerPage = isSidebarOpen ? 6 : 9;
  const { localAttendeeId } = useAppState();
  const [page, setPage] = useState(0);

  const currPageSpeakers = speakers.slice(
    page * maxAttendeePerPage,
    (page + 1) * maxAttendeePerPage
  );

  return (
    <>
      <div
        // gap-24 2xl:gap-16 xl:gap-10 lg:gap-8
        className={`mt-16 mb-24 grid ${
          currPageSpeakers.length > 4
            ? "grid-cols-3"
            : currPageSpeakers.length > 1
            ? "grid-cols-2"
            : "grid-cols-1"
        } gap-4
        ${isSidebarOpen ? " " : ""}
      `}
      >
        {currPageSpeakers.map((speaker) => (
          <SpeakerAttendeeBox
            localAttendeeId={localAttendeeId}
            speaker={speaker}
            tileId={attendeeIdToTileId[speaker?.chimeAttendeeId]}
          />
        ))}
      </div>

      <div className="flex items-center justify-center gap-6 whitespace-nowrap mt-6">
        <button
          className="h-9 w-9 rounded-full bg-white shrink-0 disabled:opacity-80"
          disabled={page === 0}
          onClick={() => setPage((p) => p - 1)}
        >
          <ChevronLeft className="mx-auto" />
        </button>
        <div className="text-lg text-white">{speakers.length} Speakers</div>
        <button
          className="h-9 w-9 rounded-full bg-white shrink-0 disabled:opacity-80"
          disabled={(page + 1) * maxAttendeePerPage >= speakers.length}
          onClick={() => setPage((p) => p + 1)}
        >
          <ChevronLeft className="mx-auto transform rotate-180" />
        </button>
      </div>
    </>
  );
}

function SpeakerAttendeeBox({ speaker, tileId, localAttendeeId, large }) {
  const { videoEnabled, muted } = useAttendeeStatus(speaker?.chimeAttendeeId);
  const { showChat, showRoster } = useNavigation();
  const isSidebarOpen = showChat || showRoster;

  const name = speaker?.name || "";
  const formattedName = name
    ? `${name.split(" ")[0]} ${name.split(" ").at(-1)[0]}.`
    : "";

  return (
    <>
      <div
        className={`flex flex-col justify-center items-center border border-solid rounded-xl h-[400px]
          ${muted ? "border-white" : "border-primary-400"}
          ${videoEnabled ? "overflow-hidden" : ""}
          ${
            large
              ? `w-full h-full lg:h-[500px] ${isSidebarOpen ? "h-[400px]" : ""}`
              : "md:w-full"
          }
        `}
      >
        {videoEnabled ? (
          <div
            className={`w-full
              ${large ? "h-full" : "h-full rounded-xl"}
            `}
          >
            {speaker?.chimeAttendeeId === localAttendeeId ? (
              <LocalVideo />
            ) : (
              <RemoteVideo
                attendeeId={speaker.chimeAttendeeId}
                tileId={tileId}
              />
            )}
          </div>
        ) : (
          <>
            <MentorAvatar attendee={speaker} small />
            <div className="flex items-center justify-center gap-2 text-lg lg:text-base text-white px-2">
              <div className="font-medium mt-2">{formattedName}</div>
              <div>
                {muted ? (
                  <MicrophoneOffSmallIcon />
                ) : (
                  <div className="text-primary-400">
                    <MicrophoneSmallIcon />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {/* {videoEnabled ? (
        <div className="flex flex-col py-6 px-14 justify-center items-center border border-solid border-white rounded-xl">
          <RemoteVideo
            attendeeId={attendee.chimeAttendeeId}
            // name={roster[tileIdToAttendeeId[tileId]]?.name}
            tileId={attendeeIdToTileId[attendee.chimeAttendeeId]}
          />
          <div className="text-white font-medium mt-2">{attendee.name}</div>
        </div>
      ) : (
        <div className="flex flex-col py-6 px-14 justify-center items-center border border-solid border-white rounded-xl">
          <MentorAvatar attendee={attendee} small />
          <div className="text-white font-medium mt-2">{attendee.name}</div>
        </div>
      )} */}
    </>
  );
}

function MentorBadge({ attendee, videoEnabled, isSpeaker }) {
  return (
    <div
      className={`bg-primary-50 rounded-full  py-3 flex items-center gap-4 
        ${
          videoEnabled
            ? "absolute top-6 left-4 z-50 pl-3 md:pr-3.5 pr-6"
            : "mt-6 md:px-3.5 px-6"
        }
      `}
    >
      {videoEnabled ? <MentorAvatar attendee={attendee} small /> : null}
      <div>
        <div className="flex items-center gap-4">
          <div
            style={{
              fontFamily: "Inter",
              fontWeight: 500,
              color: "#344054",
            }}
          >
            {attendee?.name}
          </div>
          {isSpeaker || <UnsubscribedBellIcon />}
        </div>
        {isSpeaker || (
          <div
            className="pt-2 text-left"
            style={{
              fontFamily: "Inter",
              fontWeight: 400,
              color: "#667085",
            }}
          >
            Mentor Since {attendee?.since}
          </div>
        )}
      </div>
    </div>
  );
}

function MentorAvatar({ attendee, small = false }) {
  return (
    <div>
      {attendee?.avatar ? (
        <div className="relative h-40 md:h-24 w-40 md:w-24">
          <img
            className="rounded-full border-2 border-white border-solid w-full h-full"
            src={`${process.env.REACT_APP_MEDIA_URL}${attendee?.avatar}`}
            alt="host avatar"
          />
        </div>
      ) : (
        <div
          className={`relative inset-0 flex justify-center items-center bg-dgray-50
            ${small ? "h-14 w-14" : "h-40 md:h-24 w-40 md:w-24"} rounded-full
          `}
        >
          <text className={`font-medium ${small ? "text-2xl" : "text-4xl"}`}>
            {attendee?.name ? attendee?.name[0] : ""}
          </text>
        </div>
      )}
    </div>
  );
}
