import { ReactComponent as Logo } from '../../assets/images/ddn_logo_dark.svg'
import { ReactComponent as MailIcon } from '../../assets/images/icons/mail.svg'
import { ReactComponent as PhoneIcon } from '../../assets/images/icons/phone.svg'
import { ReactComponent as FacebookIcon } from '../../assets/images/icons/facebook.svg'
import { ReactComponent as TwitterIcon } from '../../assets/images/icons/twitter.svg'
import { ReactComponent as InstagramIcon } from '../../assets/images/icons/instagram.svg'
import { ReactComponent as YoutubeIcon } from '../../assets/images/icons/youtube.svg'
import { ReactComponent as LinkedinIcon } from '../../assets/images/icons/linkedin.svg'
import { Link, useLocation } from 'react-router-dom'
import CenterContainer from '../../components/Common/Container/center'
import { CLIENT_URL, SOCIAL_LINKS } from '../../constants/index'

export default function MeetingFooter() {
  const { pathname } = useLocation()

  return (
    <footer>
      <CenterContainer className="bg-dgray-50 py-16 grid grid-cols-7 md:grid-cols-2">
        <div className="col-span-2 text-gray-500 md:mb-12">
          <Logo className="h-8 w-auto mb-8" />
          <div className="text-lg font-semibold mb-4">Contact Us</div>
          <div className="mb-3">
            <MailIcon className="inline align-top mr-3" />
            <a href="mailto:info@dingdingnow.com">info@dingdingnow.com</a>
          </div>
          <div>
            <PhoneIcon className="inline align-top mr-3" />
            <a href="tel:+1(555)000-0000">+1 (555) 000-0000</a>
          </div>
        </div>
        {items.map((item, i) => (
          <div key={i} className="md:mb-11">
            <div className="text-sm text-gray-400 font-semibold mb-4">
              {item.title}
            </div>
            <ul className="space-y-3 text-dgray-500 font-medium">
              {item.items.map(({ label, href }, j) => (
                <li key={j}>
                  {href[0] === '/' ? (
                    <a href={href} target="_blank" rel="noreferrer">
                      {label}
                    </a>
                  ) : (
                    <a href={href} target="_blank" rel="noreferrer">
                      {label}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </CenterContainer>
      <CenterContainer className="py-12 md:py-10 text-dgray-400 flex justify-between md:flex-col-reverse md:gap-6">
        <div>
          &copy; {new Date().getFullYear()} DingDingNow. All rights reserved.
        </div>
        <div className="flex gap-6">
          <a
            href={CLIENT_URL + SOCIAL_LINKS.facebook}
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon />
          </a>
          <a
            href={CLIENT_URL + SOCIAL_LINKS.twitter}
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon />
          </a>
          <a
            href={CLIENT_URL + SOCIAL_LINKS.instagram}
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon />
          </a>
          <a
            href={CLIENT_URL + SOCIAL_LINKS.youtube}
            target="_blank"
            rel="noreferrer"
          >
            <YoutubeIcon />
          </a>
          <a
            href={CLIENT_URL + SOCIAL_LINKS.linkedin}
            target="_blank"
            rel="noreferrer"
          >
            <LinkedinIcon />
          </a>
        </div>
      </CenterContainer>
    </footer>
  )
}

// the hrefs not starting with "/" will count as outbound links
const items = [
  {
    title: 'Platform',
    items: [
      { label: 'Home', href: `${CLIENT_URL}hosts` },
      { label: 'Events', href: `${CLIENT_URL}events` },
      { label: 'Hosts', href: `${CLIENT_URL}hosts` },
      { label: 'Sign in/Sign up', href: `${CLIENT_URL}register` },
      { label: 'Become a Host', href: `${CLIENT_URL}become-a-host` },
    ],
  },
  {
    title: 'Company',
    items: [
      { label: 'About us', href: `${CLIENT_URL}about-us` },
      { label: 'Contact', href: `${CLIENT_URL}contact` },
    ],
  },
  {
    title: 'Resources',
    items: [
      { label: 'DDN Blog', href: `${CLIENT_URL}blog` },
      { label: 'Help', href: `${CLIENT_URL}help` },
    ],
  },
  {
    title: 'Social',
    items: [
      { label: 'Facebook', href: SOCIAL_LINKS.facebook },
      { label: 'Twitter', href: SOCIAL_LINKS.twitter },
      { label: 'Instagram', href: SOCIAL_LINKS.instagram },
      { label: 'Youtube', href: SOCIAL_LINKS.youtube },
      { label: 'Linkedin', href: SOCIAL_LINKS.linkedin },
    ],
  },
  {
    title: 'Legal',
    items: [
      { label: 'Terms of Use', href: `${CLIENT_URL}terms` },
      { label: 'Privacy Policy', href: `${CLIENT_URL}privacy-policy` },
      { label: 'Cookies', href: `${CLIENT_URL}cookies` },
    ],
  },
]
