export default function getUserAvatarImg(user) {
  const src = user.avatar
    ? user.avatar.startsWith('https://')
      ? user.avatar
      : `${process.env.REACT_APP_MEDIA_URL}${user.avatar}`
    : null
  if (src) return <img src={src} alt="avatar" />
  else
    return (
      <div className="h-full w-full flex justify-center items-center text-sm font-medium bg-dgray-50">
        {getNameInitials(user)}
      </div>
    )
}

function getNameInitials(user) {
  if (!user?.name) return ''

  return user?.name
    .split(' ')
    .map((n) => n[0])
    .join('')
}
