import React from 'react'
import { useContentShareControls } from 'amazon-chime-sdk-component-library-react'
import { StopButton, StartButton } from './Styled'
import { useAppState } from '../../providers/AppStateProvider'
import { ATTENDEE_ROLE, COURSE_TYPE } from '../../constants'

const ContentShareControlButton = () => {
  const { toggleContentShare } = useContentShareControls()
  const { role, courseType } = useAppState()
  const isAllowed =
    courseType === COURSE_TYPE.ONE_ON_ONE || role === ATTENDEE_ROLE.HOST

  const StopYourShareIcon = () => {
    return (
      <StopButton>
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="55"
            height="55"
            rx="27.5"
            fill="#EEF2F6"
          />
          <path
            d="M38.5 28V32.9C38.5 34.8602 38.5 35.8403 38.1185 36.589C37.783 37.2475 37.2475 37.783 36.589 38.1185C35.8403 38.5 34.8602 38.5 32.9 38.5H23.1C21.1398 38.5 20.1597 38.5 19.411 38.1185C18.7525 37.783 18.217 37.2475 17.8815 36.589C17.5 35.8403 17.5 34.8602 17.5 32.9V28M32.6667 22.1667L28 17.5M28 17.5L23.3333 22.1667M28 17.5V31.5"
            stroke="#9AA4B2"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <rect
            x="0.5"
            y="0.5"
            width="55"
            height="55"
            rx="27.5"
            stroke="#CDD5DF"
          />
        </svg>
        <div className="StopButtonLabel">Share screen</div>
      </StopButton>
    )
  }

  const OpenYourShareIcon = () => {
    return (
      <StartButton>
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="55"
            height="55"
            rx="27.5"
            fill="#FCFCFD"
          />
          <path
            d="M38.5 28V32.9C38.5 34.8602 38.5 35.8403 38.1185 36.589C37.783 37.2475 37.2475 37.783 36.589 38.1185C35.8403 38.5 34.8602 38.5 32.9 38.5H23.1C21.1398 38.5 20.1597 38.5 19.411 38.1185C18.7525 37.783 18.217 37.2475 17.8815 36.589C17.5 35.8403 17.5 34.8602 17.5 32.9V28M32.6667 22.1667L28 17.5M28 17.5L23.3333 22.1667M28 17.5V31.5"
            stroke="#697586"
            stroke-width="2.33333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <rect
            x="0.5"
            y="0.5"
            width="55"
            height="55"
            rx="27.5"
            stroke="#CDD5DF"
          />
        </svg>
        <div className="StartButtonLabel">Share screen</div>
      </StartButton>
    )
  }

  return (
    <div
      onClick={() => {
        if (isAllowed) toggleContentShare()
      }}
    >
      {isAllowed ? <OpenYourShareIcon /> : <StopYourShareIcon />}
    </div>
  )
}

export default ContentShareControlButton
