import { useMeetingManager } from "amazon-chime-sdk-component-library-react";
import {
  ConsoleLogger,
  LogLevel,
  MeetingSessionConfiguration,
  VideoPriorityBasedPolicy,
  VideoPriorityBasedPolicyConfig,
} from "amazon-chime-sdk-js";

import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";

import routes from "../../constants/routes";
import { joinMeeting, getAttendee, getCreds } from "../../utils/api";
import { useAppState } from "../../providers/AppStateProvider";
import Spinner from "../../components/Spinner";
import { useAuthContext } from "../../providers/AuthProvider";
import { ATTENDEE_ROLE } from "../../constants";

import MeetingFooter from "../../containers/MeetingFooter";
import MeetingHeader from "../../containers/MeetingHeader";
import { HeaderSection, Main } from "../Meeting/Styled";

const Join = ({ match }) => {
  const location = useLocation();

  const query = qs.parse(location.search);
  const token = query.token;
  const meetingId = match.params.meetingId;

  const meetingManager = useMeetingManager();
  const { userExchangeTokenForAwsCreds } = useAuthContext();
  const history = useHistory();
  const {
    setRole,
    setMeeting,
    setToken,
    setLocalAttendeeId,
    setUserName,
    setChatCredentials,
    setCourseName,
    setCourseType,
  } = useAppState();
  setMeeting(meetingId);
  setToken(token);

  meetingManager.getAttendee = getAttendee(meetingId, token);

  useEffect(() => {
    async function getInitial() {
      let data = null;
      try {
        data = await joinMeeting(meetingId, token);
      } catch (ex) {
        history.push(routes.ERROR);
        return;
      }
      if (data) {
        setRole(data.role);
        setLocalAttendeeId(data.attendee.Attendee.AttendeeId);
        setUserName(data.name);
        setCourseName(data.courseName);
        setCourseType(data.courseType);
        const logger = new ConsoleLogger("MyLogger", LogLevel.INFO);
        const config = new MeetingSessionConfiguration(
          data.meeting.Meeting,
          data.attendee.Attendee
        );
        const unstableNetworkPreset = new VideoPriorityBasedPolicyConfig(
          0.9, // networkIssueResponseDelayFactor
          0.1 // networkIssueRecoveryDelayFactor
        );
        config.videoDownlinkBandwidthPolicy = new VideoPriorityBasedPolicy(
          logger,
          unstableNetworkPreset
        );
        meetingManager.join(config);

        if (data.role === ATTENDEE_ROLE.ATTENDEE) {
          meetingManager.audioVideo.realtimeMuteLocalAudio();
        }

        try {
          const creds = await getCreds(meetingId, token);
          setChatCredentials(creds);
          userExchangeTokenForAwsCreds(creds);
        } catch (err) {
          console.error(err);
        } finally {
          history.push(routes.DEVICE);
        }
      }
    }
    getInitial();
  }, []);

  if (!location.search) {
    return <div>Not allowed</div>;
  }
  return (
    <>
      <MeetingHeader />
      <Main>
        <HeaderSection>
          <div className="container">
            <div className="pageHeader">
              <div className="content">
                <div className="supportText">
                  <div className="text">Joining Meeting</div>
                </div>
              </div>
            </div>
          </div>
        </HeaderSection>
        <div className="container">
          <div className="w-full min-h-[150px] text-center">
            <p>Gathering meeting information, please wait...</p>
          </div>
        </div>
      </Main>
      <MeetingFooter />
    </>
  );
};
export default Join;
