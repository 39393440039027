export default function IconCircle({ children, className = '', large, color }) {
  return (
    <div
      className={`rounded-full 
        ${large ? 'w-16 h-w-16 p-2' : 'w-14 h-w-14 p-2'} 
        ${
          color === 'green'
            ? 'bg-green-50'
            : color === 'yellow'
            ? 'bg-yellow-50'
            : color === 'gray'
            ? 'bg-dgray-50'
            : 'bg-primary-50'
        }
        ${className}
      `}
    >
      <div
        className={`rounded-full flex justify-center items-center
          ${large ? 'w-12 h-12' : 'w-10 h-10'}
          ${
            color === 'green'
              ? 'bg-green-600/10 text-green-600'
              : color === 'yellow'
              ? 'bg-yellow-100 text-yellow-600'
              : color === 'gray'
              ? 'bg-dgray-100 text-dgray-600'
              : 'bg-primary-600/10 text-primary-600'
          }
        `}
      >
        {children}
      </div>
    </div>
  )
}
