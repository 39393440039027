// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect } from "react";
import {
  ControlBar,
  AudioInputControl,
  VideoInputControl,
  ContentShareControl,
  AudioOutputControl,
  useUserActivityState,
  useMeetingManager,
  ControlBarButton,
  HandRaise,
  useNotificationDispatch,
  ActionType,
  Severity,
  IconButton,
  Attendees,
  Chat,
} from "amazon-chime-sdk-component-library-react";

import EndMeetingControl from "../EndMeetingControl";
import { useNavigation } from "../../providers/NavigationProvider";
import { StyledControls } from "./Styled";
import { StartButton } from "../../components/MeetingControlsIcon/Styled";
import { useAppState } from "../../providers/AppStateProvider";
import useMeetingEndRedirect from "../../hooks/useMeetingEndRedirect";
import VideoInputControlButton from "../../components/MeetingControlsIcon/videoInputControlButton";
import AudioInputControlButton from "../../components/MeetingControlsIcon/audioInputControlButton";
import ContentShareControlButton from "../../components/MeetingControlsIcon/ContentShareControlButton";

const MeetingControls = () => {
  useMeetingEndRedirect();

  const {
    toggleNavbar,
    closeRoster,
    showRoster,
    toggleRoster,
    toggleChat,
    showChat,
  } = useNavigation();
  const { isUserActive } = useUserActivityState();
  const {
    courseName,
    localAttendeeId,
    userName,
    meetingId,
    sessionName,
    dateTime,
    mentorName,
    role,
  } = useAppState();

  const manager = useMeetingManager();

  const dispatch = useNotificationDispatch();

  const handleToggle = () => {
    if (showRoster) {
      closeRoster();
    }
    toggleNavbar();
  };

  const AddNotificationButton = () => {
    const addNotification = (e) => {
      manager.meetingSession.audioVideo.realtimeSendDataMessage(
        `management_${meetingId}`,
        {
          raiseHand: true,
          attendeeId: localAttendeeId,
          userName,
        }
      );

      dispatch({
        type: ActionType.ADD,
        payload: {
          severity: Severity.INFO,
          message: "You have raised your hand.",
          autoClose: true,
          autoCloseDelay: 5000,
        },
      });
    };
    const RaiseControlButton = () => {
      return (
        <StartButton>
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="55"
              height="55"
              rx="27.5"
              fill="#FCFCFD"
            />
            <path
              d="M22.0502 27.3519V30.5926M22.0502 27.3519V19.5741C22.0502 18.5002 22.9381 17.6297 24.0335 17.6297C25.1289 17.6297 26.0168 18.5002 26.0168 19.5741M22.0502 27.3519C22.0502 26.278 21.1622 25.4074 20.0668 25.4074C18.9715 25.4074 18.0835 26.278 18.0835 27.3519V29.9445C18.0835 35.3139 22.5233 39.6667 28.0002 39.6667C33.477 39.6667 37.9168 35.3139 37.9168 29.9445V23.463C37.9168 22.3891 37.0289 21.5186 35.9335 21.5186C34.8381 21.5186 33.9502 22.3891 33.9502 23.463M26.0168 19.5741V26.7037M26.0168 19.5741V18.2778C26.0168 17.2039 26.9048 16.3334 28.0002 16.3334C29.0955 16.3334 29.9835 17.2039 29.9835 18.2778V19.5741M29.9835 19.5741V26.7037M29.9835 19.5741C29.9835 18.5002 30.8715 17.6297 31.9668 17.6297C33.0622 17.6297 33.9502 18.5002 33.9502 19.5741V23.463M33.9502 23.463V26.7037"
              stroke="#697586"
              stroke-width="2.33333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <rect
              x="0.5"
              y="0.5"
              width="55"
              height="55"
              rx="27.5"
              stroke="#CDD5DF"
            />
          </svg>
          <div className="StartButtonLabel">Raise hand</div>
        </StartButton>
      );
    };

    return (
      <div onClick={addNotification}>
        <RaiseControlButton />
      </div>
    );
  };

  const RosterButton = () => {
    const OpenRosterButton = () => {
      return (
        <StartButton>
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="55"
              height="55"
              rx="27.5"
              fill="#FCFCFD"
            />
            <path
              d="M39.6673 38.5V36.1667C39.6673 33.9922 38.1801 32.1651 36.1673 31.647M32.084 17.8392C33.7942 18.5315 35.0007 20.2082 35.0007 22.1667C35.0007 24.1251 33.7942 25.8018 32.084 26.4941M33.834 38.5C33.834 36.3256 33.834 35.2384 33.4788 34.3808C33.0051 33.2373 32.0966 32.3289 30.9532 31.8552C30.0956 31.5 29.0084 31.5 26.834 31.5H23.334C21.1596 31.5 20.0724 31.5 19.2148 31.8552C18.0713 32.3289 17.1629 33.2373 16.6892 34.3808C16.334 35.2384 16.334 36.3256 16.334 38.5M29.7507 22.1667C29.7507 24.744 27.6613 26.8333 25.084 26.8333C22.5067 26.8333 20.4173 24.744 20.4173 22.1667C20.4173 19.5893 22.5067 17.5 25.084 17.5C27.6613 17.5 29.7507 19.5893 29.7507 22.1667Z"
              stroke="#FF692E"
              stroke-width="2.33333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <rect
              x="0.5"
              y="0.5"
              width="55"
              height="55"
              rx="27.5"
              stroke="#FFE6D5"
            />
          </svg>
          <div className="openAttendees">Attendees</div>
        </StartButton>
      );
    };
    const CloseRosterButton = () => {
      return (
        <StartButton>
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="55"
              height="55"
              rx="27.5"
              fill="#FCFCFD"
            />
            <path
              d="M39.6673 38.5V36.1667C39.6673 33.9922 38.1801 32.1651 36.1673 31.647M32.084 17.8392C33.7942 18.5315 35.0007 20.2082 35.0007 22.1667C35.0007 24.1251 33.7942 25.8018 32.084 26.4941M33.834 38.5C33.834 36.3256 33.834 35.2384 33.4788 34.3808C33.0051 33.2373 32.0966 32.3289 30.9532 31.8552C30.0956 31.5 29.0084 31.5 26.834 31.5H23.334C21.1596 31.5 20.0724 31.5 19.2148 31.8552C18.0713 32.3289 17.1629 33.2373 16.6892 34.3808C16.334 35.2384 16.334 36.3256 16.334 38.5M29.7507 22.1667C29.7507 24.744 27.6613 26.8333 25.084 26.8333C22.5067 26.8333 20.4173 24.744 20.4173 22.1667C20.4173 19.5893 22.5067 17.5 25.084 17.5C27.6613 17.5 29.7507 19.5893 29.7507 22.1667Z"
              stroke="#697586"
              stroke-width="2.33333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <rect
              x="0.5"
              y="0.5"
              width="55"
              height="55"
              rx="27.5"
              stroke="#CDD5DF"
            />
          </svg>
          <div className="StartButtonLabel">Attendees</div>
        </StartButton>
      );
    };
    return (
      <div onClick={(e) => toggleRoster()}>
        {showRoster ? <OpenRosterButton /> : <CloseRosterButton />}
      </div>
    );
  };

  const ChatButton = () => {
    const OpenChatButton = () => {
      return (
        <StartButton>
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="55"
              height="55"
              rx="27.5"
              fill="#FCFCFD"
            />
            <path
              d="M21.1107 27.1003C21.0382 26.633 21.0006 26.1542 21.0006 25.6667C21.0006 20.512 25.2068 16.3334 30.3953 16.3334C35.5839 16.3334 39.7901 20.512 39.7901 25.6667C39.7901 26.8311 39.5754 27.9457 39.1833 28.9736C39.1019 29.1871 39.0612 29.2939 39.0427 29.3772C39.0244 29.4598 39.0173 29.5178 39.0153 29.6024C39.0133 29.6877 39.0249 29.7817 39.048 29.9698L39.5177 33.785C39.5685 34.198 39.594 34.4045 39.5253 34.5546C39.4651 34.6862 39.3581 34.7907 39.2253 34.8478C39.0736 34.913 38.8677 34.8828 38.456 34.8225L34.7398 34.2778C34.5458 34.2493 34.4488 34.2351 34.3604 34.2356C34.273 34.2361 34.2125 34.2426 34.127 34.2605C34.0405 34.2787 33.9301 34.3201 33.7091 34.4029C32.6786 34.7888 31.5618 35 30.3953 35C29.9075 35 29.4283 34.9631 28.9604 34.8919M22.9042 39.6667C26.3632 39.6667 29.1673 36.7939 29.1673 33.25C29.1673 29.7062 26.3632 26.8334 22.9042 26.8334C19.4451 26.8334 16.641 29.7062 16.641 33.25C16.641 33.9624 16.7543 34.6476 16.9635 35.2879C17.0519 35.5585 17.0961 35.6939 17.1106 35.7863C17.1257 35.8828 17.1284 35.937 17.1227 36.0345C17.1173 36.128 17.094 36.2336 17.0472 36.4447L16.334 39.6667L19.8279 39.1895C20.0186 39.1635 20.114 39.1505 20.1972 39.151C20.2849 39.1516 20.3315 39.1564 20.4174 39.1735C20.4991 39.1898 20.6205 39.2326 20.8633 39.3183C21.503 39.5441 22.1896 39.6667 22.9042 39.6667Z"
              stroke="#FF692E"
              stroke-width="2.33333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <rect
              x="0.5"
              y="0.5"
              width="55"
              height="55"
              rx="27.5"
              stroke="#FFE6D5"
            />
          </svg>
          <div className="openAttendees">Event chat</div>
        </StartButton>
      );
    };
    const CloseChatButton = () => {
      return (
        <StartButton>
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="55"
              height="55"
              rx="27.5"
              fill="#FCFCFD"
            />
            <path
              d="M21.1107 27.1003C21.0382 26.633 21.0006 26.1542 21.0006 25.6667C21.0006 20.512 25.2068 16.3334 30.3953 16.3334C35.5839 16.3334 39.7901 20.512 39.7901 25.6667C39.7901 26.8311 39.5754 27.9457 39.1833 28.9736C39.1019 29.1871 39.0612 29.2939 39.0427 29.3772C39.0244 29.4598 39.0173 29.5178 39.0153 29.6024C39.0133 29.6877 39.0249 29.7817 39.048 29.9698L39.5177 33.785C39.5685 34.198 39.594 34.4045 39.5253 34.5546C39.4651 34.6862 39.3581 34.7907 39.2253 34.8478C39.0736 34.913 38.8677 34.8828 38.456 34.8225L34.7398 34.2778C34.5458 34.2493 34.4488 34.2351 34.3604 34.2356C34.273 34.2361 34.2125 34.2426 34.127 34.2605C34.0405 34.2787 33.9301 34.3201 33.7091 34.4029C32.6786 34.7888 31.5618 35 30.3953 35C29.9075 35 29.4283 34.9631 28.9604 34.8919M22.9042 39.6667C26.3632 39.6667 29.1673 36.7939 29.1673 33.25C29.1673 29.7062 26.3632 26.8334 22.9042 26.8334C19.4451 26.8334 16.641 29.7062 16.641 33.25C16.641 33.9624 16.7543 34.6476 16.9635 35.2879C17.0519 35.5585 17.0961 35.6939 17.1106 35.7863C17.1257 35.8828 17.1284 35.937 17.1227 36.0345C17.1173 36.128 17.094 36.2336 17.0472 36.4447L16.334 39.6667L19.8279 39.1895C20.0186 39.1635 20.114 39.1505 20.1972 39.151C20.2849 39.1516 20.3315 39.1564 20.4174 39.1735C20.4991 39.1898 20.6205 39.2326 20.8633 39.3183C21.503 39.5441 22.1896 39.6667 22.9042 39.6667Z"
              stroke="#697586"
              stroke-width="2.33333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <rect
              x="0.5"
              y="0.5"
              width="55"
              height="55"
              rx="27.5"
              stroke="#CDD5DF"
            />
          </svg>
          <div className="StartButtonLabel">Event chat</div>
        </StartButton>
      );
    };
    return (
      <div onClick={(e) => toggleChat()}>
        {showChat ? <OpenChatButton /> : <CloseChatButton />}
      </div>
    );
  };

  return (
    <StyledControls active={!!isUserActive}>
      <div className="controls">
        <VideoInputControlButton />
        <AudioInputControlButton />
        <ContentShareControlButton />
        <AddNotificationButton />
        <RosterButton />
        <ChatButton />
        <EndMeetingControl />
      </div>
    </StyledControls>
  );
};

export default MeetingControls;
