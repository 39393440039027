import { ReactComponent as GridIcon } from "../../assets/images/icons/grid.svg";
import { ReactComponent as CalendarIcon } from "../../assets/images/icons/calendar.svg";
import { ReactComponent as PresentationIcon } from "../../assets/images/icons/presentation-chart.svg";
import { ReactComponent as EditIcon } from "../../assets/images/icons/edit.svg";
import { ReactComponent as CreditCardUpIcon } from "../../assets/images/icons/credit-card-up.svg";
import { ReactComponent as ManageIcon } from "../../assets/images/icons/cog.svg";
import { ReactComponent as Calendar } from "../../assets/images/icons/calendar.svg";
import { ReactComponent as Cog } from "../../assets/images/icons/cog.svg";
import { ReactComponent as CreditCardUp } from "../../assets/images/icons/credit-card-up.svg";
import { ReactComponent as Edit } from "../../assets/images/icons/edit.svg";
import { ReactComponent as Grid } from "../../assets/images/icons/grid.svg";
import { ReactComponent as PresentationChart } from "../../assets/images/icons/presentation-chart.svg";
import { ReactComponent as Logout } from "../../assets/images/icons/logout.svg";
import { ReactComponent as XIcon } from "../../assets/images/icons/x.svg";
import { ReactComponent as Menu } from "../../assets/images/icons/menu.svg";
import { ReactComponent as ChevronLeft } from "../../assets/images/icons/chevron-left.svg";
import { ReactComponent as Logo } from "../../assets/images/ddn_logo_dark.svg";
// import { ReactComponent as ShoppingCart } from "../../assets/images/icons/shopping-cart.svg";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "../../components/Common/Button";
import { CLIENT_URL } from "../../constants";
// import NotificationDropdown from "components/NotificationDropdown";

export default function MobileMenu({
  user,
  isAuthenticated,
  logout,
  cartCount,
}) {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <button onClick={() => setOpen((o) => !o)} className="hidden lg:block">
        {isOpen ? <XIcon /> : <Menu />}
      </button>
      {isOpen && (
        <>
          <ItemsAuthenticated
            user={user}
            cartCount={cartCount}
            setOpen={setOpen}
            logout={logout}
          />

          <div
            className="hidden lg:block fixed inset-0 bg-dgray-700/30 z-40"
            onClick={() => setOpen(false)}
          />
        </>
      )}
    </>
  );
}

function Items({ setOpen }) {
  return (
    <div className="hidden lg:block fixed top-0 inset-x-0 z-50 bg-white text-dgray-700">
      <div className="px-4">
        <div className="flex items-center justify-between py-5">
          <div>
            <Logo className="h-9 w-[200px]" />
          </div>
          <button onClick={() => setOpen(false)}>
            <XIcon />
          </button>
        </div>
        <ul className="my-6 flex flex-col gap-1 font-medium">
          <li>
            <Link
              onClick={() => {
                setOpen(false);
              }}
              to="login"
              className="flex items-center gap-3 py-2 px-3 rounded-lg hover:bg-dgray-50"
            >
              Sign In
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setOpen(false);
              }}
              to="register"
              className="flex items-center gap-3 py-2 px-3 rounded-lg bg-primary-50 text-primary-700"
            >
              Sign up
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

function ItemsAuthenticated({ user, setOpen, logout, cartCount }) {
  const location = useLocation();
  const [isOpenNotif, setOpenNotif] = useState(false);
  const items = sidebarItemsHost;

  const isSelected = (item) => {
    return location?.pathname === item?.href;
  };

  return (
    <div className="hidden lg:block fixed top-0 inset-x-0 z-50 bg-white text-dgray-700">
      <div className="px-4">
        <div className="flex items-center justify-between py-5 border-b border-b-dgray-100">
          <div>
            <Logo className="h-8 w-full" />
          </div>
          <button onClick={() => setOpen(false)}>
            <XIcon />
          </button>
        </div>
        <div className="mt-6 mb-8 py-0.5">
          <button onClick={() => setOpen(false)} className="block">
            <ChevronLeft />
          </button>
        </div>
        <div className="flex  gap-3">
          {/* <img src={janeImg} alt="" className="rounded-full h-12 w-12" /> */}
          <div>
            <div className="text-dgray-700 font-medium">Batuhan Şahin</div>
            {/* <div className="text-dgray-700 font-medium">{user?.full_name}</div> */}
            {/* <div className="text-dgray-500">You are a student since 2019</div> */}
          </div>
        </div>
        <ul className="my-6 flex flex-col gap-1 font-medium">
          {items.map((item, i) =>
            item ? (
              i !== items.length - 1 ? (
                <li key={i}>
                  <Link
                    onClick={() => {
                      setOpen(false);
                    }}
                    to={item.href}
                    className={`flex items-center gap-3 py-2 px-3 rounded-lg
                    ${
                      isSelected(item)
                        ? "bg-primary-50 text-primary-700"
                        : "hover:bg-dgray-50"
                    }
                  `}
                  >
                    <div
                      className={
                        isSelected(item) ? "text-primary-600" : "text-dgray-500"
                      }
                    >
                      {item.icon}
                    </div>
                    {item.label}
                  </Link>
                </li>
              ) : (
                <LogoutItem
                  onClick={() => {
                    logout();
                    setOpen(false);
                  }}
                />
              )
            ) : (
              <div className="h-px bg-dgray-100 my-2" />
            )
          )}
        </ul>
      </div>

      <div className="flex justify-end items-center gap-4 py-8 px-6 bg-dgray-50 border-t border-dgray-100">
        {/* <NotificationDropdown isOpen={isOpenNotif} setOpen={setOpenNotif} /> */}
        {/* <div>NatificationDropdown</div> */}
        <Link to={`${CLIENT_URL}cart`} className="relative align-text-top mr-8">
          {cartCount > 0 && (
            <div className="absolute -top-0.5 -right-1 w-1.5 h-1.5 bg-primary-600 rounded-full" />
          )}
          {/* <ShoppingCart /> */}
          <div>Cart</div>
        </Link>

        <Button small secondary>
          My Feed
        </Button>
        <Button href="/dashboard" small className="border-4 border-primary-100">
          My DDN
        </Button>
      </div>
    </div>
  );
}

function LogoutItem({ onClick }) {
  return (
    <li>
      <Link
        onClick={onClick}
        to="/"
        className="flex items-center gap-3 py-2 px-3 rounded-lghover:bg-dgray-50"
      >
        <Logout className="text-dgray-500" />
        Logout
      </Link>
    </li>
  );
}

const sidebarItemsHost = [
  {
    icon: <GridIcon />,
    label: "Dashboard",
    href: `${CLIENT_URL}dashboard`,
  },
  {
    icon: <CalendarIcon />,
    label: "My Calendar",
    href: `${CLIENT_URL}dashboard/calendar`,
  },
  {
    icon: <PresentationIcon />,
    label: "My Events & Sessions",
    href: `${CLIENT_URL}dashboard/events`,
  },
  {
    icon: <EditIcon />,
    label: "My Blogs",
    href: `${CLIENT_URL}dashboard/blogs`,
  },
  {
    icon: <CreditCardUpIcon />,
    label: "Get Paid",
    href: `${CLIENT_URL}dashboard/get-paid`,
  },
];
