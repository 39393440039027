// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import styled from "styled-components";

export const MyFeed = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  color: #c93803;
`;
