export default function CenterContainer({ className = "", tight, children }) {
  return (
    <div
      className={`${
        tight
          ? "max-w-3xl mx-auto md:max-w-xl px-4"
          : "px-64 2xl:px-36 xl:px-24 md:px-10 sm:px-4"
      } 
          ${className}
        `}
    >
      {children}
    </div>
  );
}
