import { Link } from "react-router-dom";

export default function Button({
  children,
  onClick,
  className = "",
  href,
  to,
  small,
  secondary,
  flat,
}) {
  const classNames = `whitespace-nowrap rounded-lg text-center inline-block
  ${
    secondary
      ? "bg-primary-50 text-primary-700"
      : flat
      ? "bg-white text-dgray-500 font-semibold border border-dgray-300"
      : "bg-primary-600 text-white"
  }
  ${small ? "text-sm py-2.5 px-[1.125rem]" : "py-4 px-7"} ${className}`;

  if (href || to) {
    return (
      <Link className={classNames} onClick={onClick} to={href || to}>
        {children}
      </Link>
    );
  }
  return (
    <button className={classNames} onClick={onClick}>
      {children}
    </button>
  );
}
