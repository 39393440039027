export function MicrophoneIcon() {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="55"
        height="55"
        rx="27.5"
        fill="transparent"
      />
      <path
        d="M36.1663 25.6666V28C36.1663 32.5103 32.51 36.1666 27.9997 36.1666M19.833 25.6666V28C19.833 32.5103 23.4893 36.1666 27.9997 36.1666M27.9997 36.1666V39.6666M23.333 39.6666H32.6663M27.9997 31.5C26.0667 31.5 24.4997 29.933 24.4997 28V19.8333C24.4997 17.9003 26.0667 16.3333 27.9997 16.3333C29.9327 16.3333 31.4997 17.9003 31.4997 19.8333V28C31.4997 29.933 29.9327 31.5 27.9997 31.5Z"
        stroke="currentColor"
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function MicrophoneSmallIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 10v2a7 7 0 0 1-7 7m-7-9v2a7 7 0 0 0 7 7m0 0v3m-4 0h8m-4-7a3 3 0 0 1-3-3V5a3 3 0 1 1 6 0v7a3 3 0 0 1-3 3Z"
      />
    </svg>
  )
}

export function MicrophoneOffSmallIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 9.4V5a3 3 0 0 0-5.7-1.3M12 19v3m0-3a7 7 0 0 1-7-7v-2m7 9a7 7 0 0 0 7-7v-2M8 22h8M2 2l20 20m-10-7a3 3 0 0 1-3-3V9l5.1 5.1a3 3 0 0 1-2.1.9Z"
      />
    </svg>
  )
}

export function CameraIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M25.666 10.92c0-.707 0-1.06-.14-1.224a.583.583 0 0 0-.489-.203c-.214.017-.464.267-.964.767l-4.24 4.24 4.24 4.24c.5.5.75.75.964.767a.583.583 0 0 0 .49-.203c.14-.164.14-.517.14-1.224v-7.16Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2.333 11.933c0-1.96 0-2.94.381-3.689a3.5 3.5 0 0 1 1.53-1.53c.749-.38 1.729-.38 3.689-.38h6.3c1.96 0 2.94 0 3.689.38a3.5 3.5 0 0 1 1.53 1.53c.381.75.381 1.73.381 3.69v5.133c0 1.96 0 2.94-.381 3.689a3.5 3.5 0 0 1-1.53 1.53c-.749.38-1.729.38-3.689.38h-6.3c-1.96 0-2.94 0-3.689-.38a3.5 3.5 0 0 1-1.53-1.53c-.381-.749-.381-1.73-.381-3.69v-5.133Z"
      />
    </svg>
  )
}

export function PresentationIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none">
      <path
        stroke="#FF692E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14 18.7v5.8m0-5.8 7 5.8m-7-5.8-7 5.8m17.5-21v9.6c0 2 0 3-.4 3.7-.3.6-.9 1.1-1.5 1.5-.8.4-1.7.4-3.7.4H9.1c-2 0-3 0-3.7-.4-.6-.4-1.2-.9-1.5-1.5-.4-.8-.4-1.8-.4-3.7V3.5m5.8 7V14M14 8.2V14m4.7-1.2V14m7-10.5H2.3"
      />
    </svg>
  )
}

export function UnsubscribedBellIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
      <path
        stroke="#697586"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M7.795 17.5a3.32 3.32 0 0 0 2.205.833 3.32 3.32 0 0 0 2.205-.833M15 6.667v-5m-2.5 2.5h5m-6.667-2.43A5 5 0 0 0 5 6.667c0 2.575-.65 4.338-1.375 5.504-.612.984-.918 1.476-.907 1.613.012.152.044.21.167.3.11.083.609.083 1.606.083h11.018c.997 0 1.496 0 1.606-.082.123-.091.155-.149.167-.3.011-.138-.295-.63-.907-1.614-.41-.659-.796-1.509-1.053-2.588"
      />
    </svg>
  )
}
