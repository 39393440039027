import React, { useEffect } from "react";
import { useToggleLocalMute } from "amazon-chime-sdk-component-library-react";
import { StopButton, StartButton } from "./Styled";
import { useAppState } from "../../providers/AppStateProvider";
import { ATTENDEE_ROLE, COURSE_TYPE } from "../../constants";

const AudioInputControlButton = () => {
  const { muted, toggleMute } = useToggleLocalMute();
  const { role, courseType } = useAppState();
  const isAllowed = true;
  /*const isAllowed = courseType === COURSE_TYPE.ONE_ON_ONE ||
    role === ATTENDEE_ROLE.HOST ||
    role === ATTENDEE_ROLE.SPEAKER*/

  useEffect(() => {
    if (!isAllowed && !muted) toggleMute();
  }, []);

  return (
    <div
      onClick={() => {
        if (isAllowed) toggleMute();
      }}
    >
      {muted ? <MuteIcon /> : <UnmuteIcon />}
    </div>
  );
};

export default AudioInputControlButton;

function UnmuteIcon() {
  return (
    <StartButton>
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" fill="#FCFCFD" />
        <path
          d="M36.1663 25.6666V28C36.1663 32.5103 32.51 36.1666 27.9997 36.1666M19.833 25.6666V28C19.833 32.5103 23.4893 36.1666 27.9997 36.1666M27.9997 36.1666V39.6666M23.333 39.6666H32.6663M27.9997 31.5C26.0667 31.5 24.4997 29.933 24.4997 28V19.8333C24.4997 17.9003 26.0667 16.3333 27.9997 16.3333C29.9327 16.3333 31.4997 17.9003 31.4997 19.8333V28C31.4997 29.933 29.9327 31.5 27.9997 31.5Z"
          stroke="#697586"
          stroke-width="2.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <rect
          x="0.5"
          y="0.5"
          width="55"
          height="55"
          rx="27.5"
          stroke="#CDD5DF"
        />
      </svg>
      <div className="StartButtonLabel">Mic on</div>
    </StartButton>
  );
}

function MuteIcon() {
  return (
    <StopButton>
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" fill="#E3E8EF" />
        <path
          d="M31.4997 24.9667V19.8334C31.4997 17.9004 29.9327 16.3334 27.9997 16.3334C26.6258 16.3334 25.4368 17.125 24.8639 18.277M27.9997 36.1667V39.6667M27.9997 36.1667C23.4893 36.1667 19.833 32.5104 19.833 28V25.6667M27.9997 36.1667C32.51 36.1667 36.1663 32.5104 36.1663 28V25.6667M23.333 39.6667H32.6663M16.333 16.3334L39.6663 39.6667M27.9997 31.5C26.0667 31.5 24.4997 29.933 24.4997 28V24.5L30.476 30.4734C29.8426 31.1077 28.9669 31.5 27.9997 31.5Z"
          stroke="#9AA4B2"
          stroke-width="2.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <rect
          x="0.5"
          y="0.5"
          width="55"
          height="55"
          rx="27.5"
          stroke="#CDD5DF"
        />
      </svg>
      <div className="StopButtonLabel">Mic off</div>
    </StopButton>
  );
}
