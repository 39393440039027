// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import styled from "styled-components";

export const StopButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 4px;

  width: 64px;
  height: 72px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  .StopButtonLabel {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    /* identical to box height, or 120% */

    text-align: center;

    /* Gray / 400 */

    color: #9aa4b2;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
`;

export const StartButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 4px;

  width: 64px;
  height: 72px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  @media screen and (max-width: 768px) {
    width: 64px;
    height: 72px;
  }

  .StartButtonLabel {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    /* identical to box height, or 120% */

    text-align: center;

    /* Gray / 700 */

    color: #364152;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .openAttendees {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    /* identical to box height, or 120% */

    text-align: center;

    /* Primary / 600 */

    color: #ff692e;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
`;
