// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ControlBarButton,
  Phone,
  Modal,
  ModalBody,
  ModalHeader,
  ModalButton,
  ModalButtonGroup,
  useMeetingManager,
} from "amazon-chime-sdk-component-library-react";

import { endMeeting } from "../../utils/api";
import { StyledP, LeaveButton } from "./Styled";
import { useAppState } from "../../providers/AppStateProvider";
import routes from "../../constants/routes";
import Button from "../../components/Common/Button";
import { Dialog, Transition } from "@headlessui/react";
import { ATTENDEE_ROLE } from "../../constants";
import IconCircle from "../../components/Common/IconCircle";

const EndMeetingControl = () => {
  const meetingManager = useMeetingManager();
  const { role } = useAppState();
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const { meetingId, token } = useAppState();
  const history = useHistory();

  const leaveMeeting = async () => {
    await meetingManager.leave();
    const platformUrl = process.env.REACT_APP_PLATFORM_CLIENT_URL;
    window.location.href = `${platformUrl}/meeting-end`;
  };

  const endMeetingForAll = async () => {
    try {
      if (meetingId) {
        await endMeeting(meetingId, token);
        await meetingManager.leave();
        const platformUrl = process.env.REACT_APP_PLATFORM_CLIENT_URL;
        window.location.href = `${platformUrl}/meeting-end`;
        history.push(routes.HOME);
      }
    } catch (e) {
      console.log("Could not end meeting", e);
    }
  };

  const LeaveMeetingControlButton = () => {
    return (
      <LeaveButton>
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.38799 14.0069C3.18524 12.1393 2.28916 10.1314 1.69978 8.05489C1.51287 7.39637 1.41941 7.06711 1.41798 6.58533C1.41639 6.05074 1.60464 5.32683 1.86647 4.86075C2.10242 4.44071 2.41137 4.13176 3.02926 3.51387L3.21317 3.32996C3.83319 2.70994 4.1432 2.39994 4.47614 2.23153C5.13829 1.89662 5.92027 1.89662 6.58242 2.23153C6.91537 2.39994 7.22538 2.70994 7.84539 3.32996L8.07274 3.55731C8.48062 3.96518 8.68455 4.16912 8.81789 4.37419C9.32071 5.14757 9.32071 6.14458 8.81789 6.91795C8.68455 7.12302 8.48062 7.32696 8.07274 7.73483C7.93938 7.8682 7.8727 7.93488 7.81688 8.01283C7.61853 8.28983 7.55005 8.69147 7.64539 9.01854C7.67222 9.11058 7.70715 9.18332 7.777 9.32881C7.91797 9.62242 8.06782 9.91283 8.22657 10.1996M11.0453 13.9543L11.0977 14.0069C12.4996 15.4088 14.0847 16.5157 15.7759 17.3277C15.9214 17.3975 15.9941 17.4325 16.0861 17.4593C16.4132 17.5546 16.8149 17.4862 17.0919 17.2878C17.1698 17.232 17.2365 17.1653 17.3699 17.0319C17.7777 16.6241 17.9817 16.4201 18.1867 16.2868C18.9601 15.784 19.9571 15.784 20.7305 16.2868C20.9356 16.4201 21.1395 16.6241 21.5474 17.0319L21.7747 17.2593C22.3947 17.8793 22.7047 18.1893 22.8731 18.5223C23.2081 19.1844 23.2081 19.9664 22.8731 20.6285C22.7047 20.9615 22.3947 21.2715 21.7747 21.8915L21.5908 22.0754C20.9729 22.6933 20.664 23.0023 20.2439 23.2382C19.7778 23.5 19.0539 23.6883 18.5194 23.6867C18.0376 23.6853 17.7083 23.5918 17.0498 23.4049C13.5108 22.4004 10.1714 20.5052 7.38544 17.7192L7.33305 17.6667M22.4995 2.50002L1.49955 23.5"
            stroke="white"
            stroke-width="2.33333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div className="leaveMeetingText">Leave Event</div>
      </LeaveButton>
    );
  };

  const onEnd = async () => {
    if (role === ATTENDEE_ROLE.HOST) {
      await endMeetingForAll();
    } else {
      await leaveMeeting();
    }
  };

  const onClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <div onClick={toggleModal}>
        <LeaveMeetingControlButton />
      </div>
      <EndDialog
        role={role}
        isOpen={showModal}
        onClose={onClose}
        onEnd={onEnd}
      />
    </>
  );
};

export default EndMeetingControl;

function EndDialog({ isOpen, onEnd, onClose, role }) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-10"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-10"
        >
          <div className="fixed inset-0 bg-black bg-dgray-700/70" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="div"
                  className="flex flex-row items-center text-xl font-semibold leading-8"
                >
                  {role !== ATTENDEE_ROLE.HOST ? (
                    <>
                      <span>Leave The Meeting</span>
                    </>
                  ) : (
                    <>
                      <span>End Meeting For All</span>
                    </>
                  )}
                </Dialog.Title>
                <Dialog.Description className="mt-4 text-dgray-700">
                  {role !== ATTENDEE_ROLE.HOST
                    ? "Leave meeting now. You can join from platform if meeting did not end."
                    : "End the meeting for everyone now. The meeting cannot be used once it ends."}
                </Dialog.Description>
                <div className="flex mt-6">
                  <Button small className="mr-2 flex-1" flat onClick={onClose}>
                    Cancel
                  </Button>
                  <Button small className="flex-1" onClick={onEnd}>
                    {role !== ATTENDEE_ROLE.HOST ? "Leave" : "End Meeting"}
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
