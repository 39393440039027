// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import axios from "axios";
import { DEFAULT_MEDIA_REGION } from "../constants";
let BASE_URL = process.env.REACT_APP_CHIME_BACKEND_URL;
const client = () => {
  return axios.create({
    baseURL: BASE_URL,
  });
};

export async function fetchMeeting(meetingId, name, region) {
  const response = await fetch(
    `${BASE_URL}join?title=${encodeURIComponent(
      meetingId
    )}&name=${encodeURIComponent(name)}${
      region ? `&region=${encodeURIComponent(region)}` : ""
    }`,
    {
      method: "POST",
    }
  );
  const data = await response.json();

  if (data.error) {
    throw new Error(`Server error: ${data.error}`);
  }

  return data;
}

export async function joinMeeting(meetingId, token) {
  const region = await getNearestMediaRegion();
  const response = await client().post(
    `/meeting/join/${meetingId}`,
    {
      region,
    },
    {
      headers: {
        "X-Auth-Token": token,
      },
    }
  );

  return response.data;
}

export function getAttendee(meetingId, token) {
  return async (attendeeId, externalUserId) => {
    const response = await client().post(
      `/attendee/${meetingId}`,
      {
        attendeeId,
      },
      {
        headers: {
          "X-Auth-Token": token,
        },
      }
    );
    return {
      name: response.data.name,
      role: response.data.role,
      isCoHost: response.data.isCoHost,
      isVerified: response.data.isVerified,
      avatar: response.data.avatar,
      since: response.data.since,
      userArn: response.data.appInstanceUserArn,
    };
  };
}

export async function getCreds(meetingId, token) {
  const res = await client().post(
    `${BASE_URL}/creds/${meetingId}`,
    {},
    {
      headers: {
        "X-Auth-Token": token,
      },
    }
  );

  if (!res) {
    throw new Error("Server error ending meeting");
  }

  return res.data;
}

export function createGetAttendeeCallback(meetingId) {
  return async (chimeAttendeeId, externalUserId) => {
    const attendeeUrl = `${BASE_URL}attendee?title=${encodeURIComponent(
      meetingId
    )}&attendee=${encodeURIComponent(chimeAttendeeId)}`;
    const res = await fetch(attendeeUrl, {
      method: "GET",
    });

    if (!res.ok) {
      throw new Error("Invalid server response");
    }

    const data = await res.json();

    return {
      name: data.AttendeeInfo.Name,
    };
  };
}

export async function endMeeting(meetingId, token) {
  const res = await client().post(
    `${BASE_URL}/end/${meetingId}`,
    {},
    {
      headers: {
        "X-Auth-Token": token,
      },
    }
  );

  if (!res) {
    throw new Error("Server error ending meeting");
  }
}

export async function getNearestMediaRegion() {
  try {
    const res = await fetch(`https://nearest-media-region.l.chime.aws`, {
      method: "GET",
    });

    if (!res.ok) {
      throw new Error("Server error");
    }

    const data = await res.json();
    const nearestRegion = data.region;
    return nearestRegion;
  } catch (err) {
    return DEFAULT_MEDIA_REGION;
  }
}
