// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import styled from "styled-components";

export const StyledP = styled.p`
  padding: 1rem 1rem 1rem 0;
`;

export const LeaveButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 165px;
  height: 56px;

  /* Error / 600 */

  background: #d92d20;
  border-radius: 100px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;

  .leaveMeetingText {
    padding-left: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    /* Base / White */

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
`;
