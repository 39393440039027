// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useState } from "react";
import {
  Roster,
  RosterHeader,
  RosterGroup,
  useRosterState,
  RosterAttendee,
  PopOverItem,
  useMeetingManager,
  MicrophoneActivity,
} from "amazon-chime-sdk-component-library-react";

import { useNavigation } from "../../providers/NavigationProvider";
import { useAppState } from "../../providers/AppStateProvider";
import { AttendeesSection } from "./Styled";
import { COURSE_TYPE } from "../../constants";
import getUserAvatarImg from "../../utils/getUserAvatar";

const MeetingRoster = ({ showRoster }) => {
  const { roster } = useRosterState();
  const [filter, setFilter] = useState("");
  const { closeRoster } = useNavigation();

  const { role, meetingId, courseType } = useAppState();

  const manager = useMeetingManager();

  let attendees = Object.values(roster);

  // if (filter) {
  //   attendees = attendees.filter((attendee) =>
  //     attendee?.name?.toLowerCase().includes(filter.trim().toLowerCase())
  //   );
  // }

  const handleSearch = (e) => {
    setFilter(e.target.value);
  };

  const muteUser = (userId) => (e) => {
    manager.meetingSession.audioVideo.realtimeSendDataMessage(
      `management_${meetingId}`,
      {
        muteAll: true,
      }
    );
  };

  const getMenu = (externalUserId) => {
    return (
      <>
        <PopOverItem
          as="button"
          href="https://www.amazon.com"
          children={<span>Mute</span>}
          onClick={muteUser(externalUserId)}
        />
        <PopOverItem
          as="button"
          onClick={() => {}}
          children={<span>Option 1</span>}
        />
      </>
    );
  };
  const microphoneIcon = () => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.8327 8.33341V10.0001C15.8327 13.2217 13.221 15.8334 9.99935 15.8334M4.16602 8.33341V10.0001C4.16602 13.2217 6.77769 15.8334 9.99935 15.8334M9.99935 15.8334V18.3334M6.66602 18.3334H13.3327M9.99935 12.5001C8.61864 12.5001 7.49935 11.3808 7.49935 10.0001V4.16675C7.49935 2.78604 8.61864 1.66675 9.99935 1.66675C11.3801 1.66675 12.4993 2.78604 12.4993 4.16675V10.0001C12.4993 11.3808 11.3801 12.5001 9.99935 12.5001Z"
          stroke="#697586"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };

  const attendeeItemsAll = attendees.map((attendee) => {
    return (
      <div className="flex items-center justify-between w-full mb-2">
        <div class="flex gap-2 items-center">
          <div class="relative w-8 h-8 rounded-full overflow-hidden">
            {getUserAvatarImg(attendee)}
          </div>
          <div className="text-dgray-700 font-medium">{attendee.name}</div>
        </div>
        <div className="">
          <MicrophoneActivity
            attendeeId={attendee.chimeAttendeeId}
            className="MicrophoneActivity"
          />
        </div>
      </div>
    );
  });

  const attendeeItemsHost = attendees
    .filter((attendee) => attendee.role === 1)
    .map((attendee) => {
      const { chimeAttendeeId, externalUserId } = attendee || {};
      /*if (role === 1 && externalUserId !== localExternalUserId) {
      return (
        <RosterAttendee
          key={chimeAttendeeId}
          attendeeId={chimeAttendeeId}
          buttonProps={{ className: "asdasd" }}
          className="test"
          menu={getMenu(externalUserId)}
        />
      );
    } else {
      return (
        <RosterAttendee key={chimeAttendeeId} attendeeId={chimeAttendeeId} />
      );
    }*/
      return (
        <div className="flex flex-row items-center w-full">
          <div class="flex basis-1/6">
            <div class="relative w-8 h-8 rounded-full overflow-hidden">
              {getUserAvatarImg(attendee)}
            </div>
          </div>
          <div
            className="basis-3/4"
            style={{ fontFamily: "Inter", fontWeight: 500, color: 344054 }}
          >
            {attendee.name}
          </div>
          <div className="basis-1/4">
            <MicrophoneActivity
              attendeeId={attendee.chimeAttendeeId}
              className="MicrophoneActivity"
            />
          </div>
        </div>
      );
    });

  const attendeeItemsAttendee = attendees
    .filter((attendee) => attendee.role === 0)
    .map((attendee) => {
      const { chimeAttendeeId, externalUserId } = attendee || {};
      return (
        <div className="flex flex-row items-center w-full">
          <div class="flex basis-1/6">
            <div class="relative w-8 h-8 rounded-full overflow-hidden">
              {getUserAvatarImg(attendee)}
            </div>
          </div>
          <div
            className="basis-3/4"
            style={{ fontFamily: "Inter", fontWeight: 500, color: 344054 }}
          >
            {attendee.name}
          </div>
          <div className="basis-1/4">
            <MicrophoneActivity
              attendeeId={attendee.chimeAttendeeId}
              className="MicrophoneActivity"
            />
          </div>
        </div>
      );
    });

  const attendeeItemsSpeaker = attendees
    .filter((attendee) => attendee.role === 2)
    .map((attendee) => {
      return (
        <div className="flex flex-row items-center w-full">
          <div class="flex basis-1/6">
            <div class="relative w-8 h-8 rounded-full overflow-hidden">
              {getUserAvatarImg(attendee)}
            </div>
          </div>
          <div
            className="basis-3/4"
            style={{ fontFamily: "Inter", fontWeight: 500, color: 344054 }}
          >
            {attendee.name}
          </div>
          <div className="basis-1/4">
            <MicrophoneActivity
              attendeeId={attendee.chimeAttendeeId}
              className="MicrophoneActivity"
            />
          </div>
        </div>
      );
    });

  let extra = {};

  if (role === 1) {
    extra.menu = (
      <PopOverItem
        as="button"
        children={<span>Mute Everyone</span>}
        onClick={muteUser()}
      />
    );
  }

  if (!showRoster) {
    return null;
  }

  return (
    <AttendeesSection>
      <div className="attendeesContainer">
        <div className="attendeesList">
          <div className="attendeesHeading">
            <div className="attendeesHeadingIcon-1">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.2008 7.80005L7.80078 16.2M7.80078 7.80005L16.2008 16.2"
                  stroke="#98A2B3"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="attendeesHeadingText">Attendees</div>
            <button className="attendeesHeadingIcon-2" onClick={closeRoster}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.2008 7.80005L7.80078 16.2M7.80078 7.80005L16.2008 16.2"
                  stroke="#98A2B3"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className="attendeesMain">
            {courseType === COURSE_TYPE.ONE_ON_ONE ? (
              <div className="attendeesMain-1">
                <div className="audience">
                  <div className="audience-header">
                    <div className="audience-header-divider">
                      <svg
                        width="184"
                        height="2"
                        viewBox="0 0 184 2"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect y="0.5" width="184" height="1" fill="#EAECF0" />
                      </svg>
                    </div>
                  </div>
                  <div className="audience-main">
                    <div className="audience-main-1">
                      <div className="avatar-label-group">
                        <div className="avatar-label-group-2">
                          <div className="avatar-label-group-2-text">
                            {attendeeItemsAll}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="attendeesMain-1">
                <div className="audience">
                  <div className="audience-header">
                    <div className="audience-header-text">
                      Event Host ({attendeeItemsHost.length})
                    </div>
                    <div className="audience-header-divider">
                      <svg
                        width="184"
                        height="2"
                        viewBox="0 0 184 2"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect y="0.5" width="184" height="1" fill="#EAECF0" />
                      </svg>
                    </div>
                  </div>
                  <div className="audience-main">
                    <div className="audience-main-1">
                      <div className="avatar-label-group">
                        <div className="avatar-label-group-2">
                          <div className="avatar-label-group-2-text space-y-2">
                            {attendeeItemsHost}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="audience">
                  <div className="audience-header">
                    <div className="audience-header-text">
                      Speakers ({attendeeItemsSpeaker.length})
                    </div>
                    <div className="audience-header-divider"></div>
                  </div>
                  <div className="audience-main">
                    <div className="audience-main-1">
                      <div className="avatar-label-group">
                        <div className="avatar-label-group-2">
                          {attendeeItemsSpeaker}
                        </div>
                      </div>
                      <div className="microphoneIcon"></div>
                    </div>
                  </div>
                </div>
                <div className="audience">
                  <div className="audience-header">
                    <div className="audience-header-text">
                      Audience ({attendeeItemsAttendee.length})
                    </div>
                    <div className="audience-header-divider">
                      <svg
                        width="184"
                        height="2"
                        viewBox="0 0 184 2"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect y="0.5" width="184" height="1" fill="#EAECF0" />
                      </svg>
                    </div>
                  </div>
                  <div className="audience-main">
                    <div className="audience-main-1">
                      <div className="avatar-label-group">
                        {/* <div className="avatar-label-group-1"></div> */}
                        <div className="avatar-label-group-2">
                          {attendeeItemsAttendee}
                        </div>
                      </div>
                      <div className="microphoneIcon"></div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="attendeesMainScrollBar">
              <div className="attendeesMainScrollBar-1">
                <div className="attendeesMainBar"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AttendeesSection>
  );
};

export default MeetingRoster;
