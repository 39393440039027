// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useContext, useState } from "react";

const AppStateContext = React.createContext(null);

export function useAppState() {
  const state = useContext(AppStateContext);

  if (!state) {
    throw new Error("useAppState must be used within AppStateProvider");
  }

  return state;
}

const query = new URLSearchParams(window.location.search);

export function AppStateProvider({ children }) {
  const [meetingId, setMeeting] = useState(query.get("meetingId") || "");
  const [course, setCourse] = useState(null);
  const [region, setRegion] = useState(query.get("region") || "");
  const [userName, setUserName] = useState(null);
  const [courseName, setCourseName] = useState(null);
  const [courseType, setCourseType] = useState(null);
  const [role, setRole] = useState(null);
  const [localAttendeeId, setLocalAttendeeId] = useState(null);
  const [settings, setSettings] = useState(null);
  const [token, setToken] = useState(null);
  const [chatCredentials, setChatCredentials] = useState(null);
  const [theme, setTheme] = useState(() => {
    const storedTheme = localStorage.getItem("theme");
    return storedTheme || "light";
  });

  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
      localStorage.setItem("theme", "dark");
    } else {
      setTheme("light");
      localStorage.setItem("theme", "light");
    }
  };

  const setAppMeetingInfo = (meetingId, name, region, course) => {
    setRegion(region);
    setMeeting(meetingId);
    setUserName(name);
    setCourse(course);
  };

  const providerValue = {
    meetingId,
    userName,
    courseName,
    courseType,
    theme,
    region,
    role,
    localAttendeeId,
    settings,
    course,
    token,
    chatCredentials,
    toggleTheme,
    setAppMeetingInfo,
    setMeeting,
    setRole,
    setLocalAttendeeId,
    setUserName,
    setCourseName,
    setCourseType,
    setSettings,
    setToken,
    setChatCredentials,
  };

  return (
    <AppStateContext.Provider value={providerValue}>
      {children}
    </AppStateContext.Provider>
  );
}
