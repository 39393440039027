// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { Fragment, useEffect, useState } from "react";
import {
  Heading,
  useAudioInputs,
  useMeetingManager,
  UserActivityProvider,
  useVideoInputs,
} from "amazon-chime-sdk-component-library-react";

import JoinMeetingDetails from "../../containers/MeetingJoinDetails";
import { StyledLayout } from "./Styled";
import DeviceSelection from "../../components/DeviceSelection";
import CenterContainer from "../../components/Common/Container/center";
import IconCircle from "../../components/Common/IconCircle";
import {
  CameraIcon,
  MicrophoneIcon,
  PresentationIcon,
} from "../../components/Common/Icon";
import Button from "../../components/Common/Button";
import { useAppState } from "../../providers/AppStateProvider";
import { MessagingProvider } from "../../providers/ChatMessagesProvider";
import MeetingFooter from "../../containers/MeetingFooter";
import { CLIENT_URL, ATTENDEE_ROLE } from "../../constants";
import { ReactComponent as ArrowLeft } from "../../assets/images/icons/arrow-left.svg";
import { Dialog, Transition } from "@headlessui/react";
import SpeakerDevices from "../../components/DeviceSelection/SpeakerDevices";
import MicrophoneDevices from "../../components/DeviceSelection/MicrophoneDevices";
import CameraDevices from "../../components/DeviceSelection/CameraDevices";
import { useHistory } from "react-router-dom";
import routes from "../../constants/routes";
import MeetingHeader from "../../containers/MeetingHeader";

const DeviceSetup = () => {
  const meetingManager = useMeetingManager();
  const history = useHistory();

  const { meetingId, localAttendeeId, courseName, courseType, role } =
    useAppState();
  const [error, setError] = useState(null);

  /*
      <Heading tag="h1" level={3} css="align-self: flex-start">
        Device settings
      </Heading>
      <DeviceSelection />
      <JoinMeetingDetails />
  */

  const [hasJoined, setHasJoined] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const onClickAttend = () => {
    setIsOpen(true);
  };

  const onActivate = async () => {
    try {
      await meetingManager.start();
      setHasJoined(true);
      history.push(`${routes.MEETING}/${meetingId}`);
    } catch (error) {
      setError(error.message);

      setIsOpen(false);
    }
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <UserActivityProvider>
      <MessagingProvider>
        <MeetingHeader />
        {error ? (
          <ErrorView onClickAttend={onClickAttend} error={error} />
        ) : (
          <>
            {!hasJoined && role > -1 ? (
              role === ATTENDEE_ROLE.HOST ? (
                <HostInitialView onClickAttend={onClickAttend} />
              ) : courseType === 1 || role === ATTENDEE_ROLE.SPEAKER ? (
                <SpeakerInitialView onClickAttend={onClickAttend} />
              ) : (
                <AudienceInitialView
                  onClickAttend={onClickAttend}
                  courseName={courseName}
                />
              )
            ) : null}
          </>
        )}
        <MeetingFooter />
        <SetupDialog
          isOpen={isOpen}
          role={courseType === 1 ? ATTENDEE_ROLE.SPEAKER : role}
          onClose={onClose}
          onActivate={onActivate}
        />
      </MessagingProvider>
    </UserActivityProvider>
  );
};

export default DeviceSetup;

function SetupDialog({ isOpen, onActivate, onClose, role }) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-10"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-10"
        >
          <div className="fixed inset-0 bg-black bg-dgray-700/70" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="div"
                  className="flex flex-row items-center text-xl font-semibold"
                >
                  {role !== ATTENDEE_ROLE.ATTENDEE ? (
                    <>
                      <IconCircle className="mr-2" large>
                        <CameraIcon />
                      </IconCircle>
                      <span>Video and Microphone access needed</span>
                    </>
                  ) : (
                    <>
                      <IconCircle className="mr-2" large>
                        <MicrophoneIcon />
                      </IconCircle>
                      <span>Speaker access needed</span>
                    </>
                  )}
                </Dialog.Title>
                <Dialog.Description className="mt-4 text-dgray-700">
                  {role !== ATTENDEE_ROLE.ATTENDEE
                    ? "Please allow input video and microphone access request from your browser to continue to event."
                    : "Please allow input microphone access request from your browser to hear voices from the event."}
                </Dialog.Description>
                <div className="flex mt-6">
                  {role !== ATTENDEE_ROLE.ATTENDEE ? (
                    <>
                      <div className="flex-1 flex flex-col mr-4">
                        <MicrophoneDevices />
                        <SpeakerDevices />
                      </div>
                      <div className="flex-1 flex flex-col">
                        <CameraDevices />
                      </div>
                    </>
                  ) : (
                    <>
                      <SpeakerDevices />
                    </>
                  )}
                </div>
                <div className="flex mt-4">
                  <Button small className="mr-2 flex-1" flat onClick={onClose}>
                    Cancel
                  </Button>
                  <Button small className="flex-1" onClick={onActivate}>
                    Activate
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

function SpeakerInitialView({ onClickAttend }) {
  const { courseType } = useAppState();
  return (
    <CenterContainer>
      <div className="flex flex-col items-center text-center py-24">
        <IconCircle className="mb-6" large>
          <MicrophoneIcon />
        </IconCircle>
        <div className="text-3xl font-semibold mb-8">
          Microphone Access Needed for the Event
        </div>
        <div className="text-lg text-primary-600 font-semibold mb-3">
          Your role: Speaker
        </div>
        <p className="text-dgray-500 mb-16">
          {courseType === 1 ? (
            <>
              Please note that in a private event, you will be able to interact
              with the event host. <br />
              You can mute/unmute yourself, share screen and chat with the event
              host.
            </>
          ) : (
            <>
              Please note that as a speaker member, you will be able to interact
              with the event host and other speakers. You can mute/unmute
              yourself and chat with the event attendees to share, contribute
              and talk!
            </>
          )}
        </p>
        <div>
          <Button onClick={onClickAttend}>Attend the Event</Button>
        </div>
      </div>
    </CenterContainer>
  );
}

function HostInitialView({ onClickAttend }) {
  return (
    <CenterContainer>
      <div className="flex flex-col items-center text-center py-24">
        <IconCircle className="mb-6" large>
          <CameraIcon />
        </IconCircle>
        <div className="text-3xl font-semibold mb-8">
          Video & Audio Access Needed for the Event
        </div>
        <p className="text-dgray-500 mb-16">
          Please give your browser video and audio permissions to start your
          event.
        </p>
        <Button onClick={onClickAttend}>Join the Event</Button>
      </div>
    </CenterContainer>
  );
}

function ErrorView({ onClickAttend, error }) {
  return (
    <CenterContainer>
      <div className="flex flex-col items-center text-center py-24">
        <IconCircle className="mb-6" large>
          <CameraIcon />
        </IconCircle>
        <div className="text-3xl font-semibold mb-8">Failed To Join Event</div>
        <p className="text-dgray-500 mb-16">{error}</p>
        <Button onClick={onClickAttend}>Retry To Join</Button>
      </div>
    </CenterContainer>
  );
}

function AudienceInitialView({ onClickAttend, courseName }) {
  return (
    <CenterContainer>
      <div className="flex flex-col items-center text-center py-8">
        <IconCircle className="mb-6" large>
          <PresentationIcon />
        </IconCircle>
        <div className="text-3xl font-semibold mb-8">{courseName}</div>
        <div className="text-lg text-primary-600 font-semibold mb-3">
          Your role: Audience
        </div>
        <p className="text-dgray-500 mb-8">
          Please note that as an audience member, your microphone and video will
          be turned off during the event. You can chat with the event audience
          to share, contribute and talk!
        </p>
        <p className="text-dgray-500 mb-2">
          On the speaker waitlist? Check your notifications to see if there is
          available seat.
        </p>
        <a
          className="text-sm text-primary-700 font-medium mb-16"
          href={`${CLIENT_URL}notifications`}
          target="_blank"
          rel="noreferrer"
        >
          Notifications
        </a>
        <Button onClick={onClickAttend} className="mb-6">
          Attend the Event
        </Button>
        <a
          className="text-sm text-dgray-500 font-medium mb-16 flex gap-1"
          href={`${CLIENT_URL}dashboard`}
          target="_blank"
          rel="noreferrer"
        >
          <ArrowLeft />
          Back to My DDN
        </a>
      </div>
    </CenterContainer>
  );
}
