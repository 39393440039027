// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

export const rootPath = "/";

const routes = {
  HOME: `${rootPath}`,
  DEVICE: `${rootPath}devices`,
  MEETING: `${rootPath}meeting`,
  ERROR: `${rootPath}error`,
  JOIN: `${rootPath}join/:meetingId`,
};

export default routes;
