/* eslint-disable no-plusplus */
/* eslint-disable no-console */
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

// eslint-disable-next-line no-unused-vars
import AWS from "aws-sdk";
import { getCreds } from "./api";
const ChimeIdentity = require("aws-sdk/clients/chimesdkidentity");
const ChimeMessaging = require("aws-sdk/clients/chimesdkmessaging");

export const createMemberArn = (userId) =>
  `${process.env.REACT_APP_APPINSTANCE_ARN}/user/${userId}`;

const appInstanceUserArnHeader = "x-amz-chime-bearer";

let chimeMessaging = null;
let chimeIdentity = null;
let credentials = null;
let awsClient = null;
let expirationDate = null

export async function setAWSConfig(awsCreds) {
  expirationDate = awsCreds.Expiration
  AWS.config.region = "us-east-1";
  AWS.config.credentials = new AWS.Credentials(
    awsCreds.AccessKeyId,
    awsCreds.SecretAccessKey,
    awsCreds.SessionToken
  );

  AWS.config.credentials.needsRefresh = () => {
    const exp = new Date(expirationDate);
    return Date.now() > exp.getTime();
  };

  return new Promise((resolve) => {
    AWS.config.credentials.get(() => {
      resolve(AWS);
    });
  });
}

// Setup Chime Client lazily
export async function chimeMessagingClient(creds) {
  if (creds == null) {
    return chimeMessaging;
  }

  awsClient = await setAWSConfig(creds);
  chimeMessaging = new ChimeMessaging({
    region: "us-east-1"
  });

  return { chimeMessaging, awsClient }
}

async function chimeIdentityClient() {
  if (chimeIdentity == null) {
    chimeIdentity = new ChimeIdentity();
  }
  return chimeIdentity;
}

async function getMessagingSessionEndpoint() {
  const request = (await chimeMessagingClient()).getMessagingSessionEndpoint();
  const response = await request.promise();
  return response;
}

async function sendChannelMessage(
  channelArn,
  messageContent,
  member,
  options = null
) {
  console.log("sendChannelMessage called");

  const chimeBearerArn = createMemberArn(member.userId);

  const params = {
    ChimeBearer: chimeBearerArn,
    ChannelArn: channelArn,
    Content: messageContent,
    Persistence: "PERSISTENT", // Allowed types are PERSISTENT and NON_PERSISTENT
    Type: "STANDARD", // Allowed types are STANDARD and CONTROL
  };
  if (options && options.Metadata) {
    params.Metadata = options.Metadata;
  }

  const request = (await chimeMessagingClient()).sendChannelMessage(params);
  const response = await request.promise();
  const sentMessage = {
    response: response,
    CreatedTimestamp: new Date(),
    Sender: { Arn: createMemberArn(member.userId), Name: member.username },
  };
  return sentMessage;
}

async function getChannelMessage(channelArn, member, messageId) {
  console.log("getChannelMessage called");

  const chimeBearerArn = createMemberArn(member.userId);

  const params = {
    ChannelArn: channelArn,
    MessageId: messageId,
    ChimeBearer: chimeBearerArn,
  };

  const request = (await chimeMessagingClient()).getChannelMessage(params);
  const response = await request.promise();
  return response.ChannelMessage;
}

async function listChannelMessages(channelArn, userId, nextToken = null) {
  console.log("listChannelMessages called");

  const chimeBearerArn = createMemberArn(userId);

  const params = {
    ChannelArn: channelArn,
    NextToken: nextToken,
    ChimeBearer: chimeBearerArn,
  };

  const request = (await chimeMessagingClient()).listChannelMessages(params);
  const response = await request.promise();
  const messageList = response.ChannelMessages;
  messageList.sort(function (a, b) {
    // eslint-disable-next-line no-nested-ternary
    return a.CreatedTimestamp < b.CreatedTimestamp
      ? -1
      : a.CreatedTimestamp > b.CreatedTimestamp
      ? 1
      : 0;
  });

  const messages = [];
  for (let i = 0; i < messageList.length; i++) {
    const message = messageList[i];
    messages.push(message);
  }
  return { Messages: messages, NextToken: response.NextToken };
}

async function listAppInstanceUsers(appInstanceArn, userId, nextToken = null) {
  console.log("listAppInstanceUsers called");
  const chimeBearerArn = createMemberArn(userId);
  const params = {
    AppInstanceArn: appInstanceArn,
    NextToken: nextToken,
    ChimeBearer: chimeBearerArn,
  };

  const request = (await chimeIdentityClient()).listAppInstanceUsers(params);
  request.on("build", function () {
    request.httpRequest.headers[appInstanceUserArnHeader] =
      createMemberArn(userId);
  });
  const response = await request.promise();
  return response.AppInstanceUsers;
}

async function createChannelMembership(channelArn, memberArn, userId) {
  console.log("createChannelMembership called");
  const chimeBearerArn = createMemberArn(userId);
  const params = {
    ChannelArn: channelArn,
    MemberArn: memberArn,
    Type: "DEFAULT", // OPTIONS ARE: DEFAULT and HIDDEN
    ChimeBearer: chimeBearerArn,
  };

  const request = (await chimeMessagingClient()).createChannelMembership(
    params
  );
  const response = await request.promise();
  return response.Member;
}

async function deleteChannelMembership(channelArn, memberArn, userId) {
  console.log("deleteChannelMembership called");
  const chimeBearerArn = createMemberArn(userId);

  const params = {
    ChannelArn: channelArn,
    MemberArn: memberArn,
    ChimeBearer: chimeBearerArn,
  };

  const request = (await chimeMessagingClient()).deleteChannelMembership(
    params
  );
  const response = await request.promise();
  return response;
}

async function createChannelBan(channelArn, memberArn, userId) {
  console.log("createChannelBan called");
  const chimeBearerArn = createMemberArn(userId);

  const params = {
    ChannelArn: channelArn,
    MemberArn: memberArn,
    ChimeBearer: chimeBearerArn,
  };

  const request = (await chimeMessagingClient()).createChannelBan(params);
  const response = await request.promise();
  return response;
}

async function deleteChannelBan(channelArn, memberArn, userId) {
  console.log("deleteChannelBan called");

  const chimeBearerArn = createMemberArn(userId);
  const params = {
    ChannelArn: channelArn,
    MemberArn: memberArn,
    ChimeBearer: chimeBearerArn,
  };

  const request = (await chimeMessagingClient()).deleteChannelBan(params);
  const response = await request.promise();
  return response;
}

async function listChannelBans(channelArn, maxResults, nextToken, userId) {
  console.log("listChannelBans called");

  const chimeBearerArn = createMemberArn(userId);
  const params = {
    ChannelArn: channelArn,
    MaxResults: maxResults,
    NextToken: nextToken,
    ChimeBearer: chimeBearerArn,
  };

  const request = (await chimeMessagingClient()).listChannelBans(params);
  const response = await request.promise();
  console.log("listChannelBans response", response);
  return response;
}

async function listChannelMemberships(channelArn, userId) {
  console.log("listChannelMemberships called");
  const chimeBearerArn = createMemberArn(userId);

  const params = {
    ChannelArn: channelArn,
    ChimeBearer: chimeBearerArn,
  };

  const request = (await chimeMessagingClient()).listChannelMemberships(params);
  const response = await request.promise();
  return response.ChannelMemberships;
}

async function associateChannelFlow(channelArn, channelFlowArn, userId) {
  console.log("associateChannelFlow called");
  const chimeBearerArn = createMemberArn(userId);
  const params = {
    ChannelArn: channelArn,
    ChannelFlowArn: channelFlowArn,
    ChimeBearer: chimeBearerArn,
  };

  const request = (await chimeMessagingClient()).associateChannelFlow(params);

  const response = await request.promise();
  console.log("associateChannelFlow response");
  console.log(response);
  return response;
}

async function disassociateChannelFlow(channelArn, channelFlowArn, userId) {
  console.log("disassociateChannelFlow called");
  const chimeBearerArn = createMemberArn(userId);
  const params = {
    ChannelArn: channelArn,
    ChannelFlowArn: channelFlowArn,
    ChimeBearer: chimeBearerArn,
  };

  const request = (await chimeMessagingClient()).disassociateChannelFlow(
    params
  );

  const response = await request.promise();
  return response;
}

async function describeChannelFlow(channelFlowArn) {
  console.log("describeChannelFlow called");
  const params = {
    ChannelFlowArn: channelFlowArn,
  };

  const request = (await chimeMessagingClient()).describeChannelFlow(params);
  const response = await request.promise();
  return response.ChannelFlow;
}

async function listChannelFlows(appInstanceArn, maxResults, nextToken) {
  console.log("listChannelFlows called");

  const params = {
    AppInstanceArn: appInstanceArn,
    MaxResults: maxResults,
    NextToken: nextToken,
  };

  const request = (await chimeMessagingClient()).listChannelFlows(params);
  const response = await request.promise();
  console.log("listChannelFlows response", response);
  return response.ChannelFlows;
}

async function createChannel(
  appInstanceArn,
  metadata,
  name,
  mode,
  privacy,
  userId
) {
  console.log("createChannel called");

  const chimeBearerArn = createMemberArn(userId);
  const params = {
    AppInstanceArn: appInstanceArn,
    Metadata: metadata,
    Name: name,
    Mode: mode,
    Privacy: privacy,
    ChimeBearer: chimeBearerArn,
  };

  const request = (await chimeMessagingClient()).createChannel(params);
  request.on("build", function () {
    request.httpRequest.headers[appInstanceUserArnHeader] =
      createMemberArn(userId);
  });
  const response = await request.promise();
  return response.ChannelArn;
}

async function describeChannel(channelArn, userId) {
  console.log("describeChannel called");

  const chimeBearerArn = createMemberArn(userId);

  const params = {
    ChannelArn: channelArn,
    ChimeBearer: chimeBearerArn,
  };

  const request = (await chimeMessagingClient()).describeChannel(params);
  const response = await request.promise();
  return response.Channel;
}

async function updateChannel(channelArn, name, mode, metadata, userId) {
  console.log("updateChannel called");

  const chimeBearerArn = createMemberArn(userId);
  console.log(chimeBearerArn);
  const params = {
    ChannelArn: channelArn,
    Name: name,
    Mode: mode,
    Metadata: metadata,
    ChimeBearer: chimeBearerArn,
  };

  const request = (await chimeMessagingClient()).updateChannel(params);
  const response = await request.promise();
  return response;
}

async function listChannelMembershipsForAppInstanceUser(userId) {
  console.log("listChannelMembershipsForAppInstanceUser called");
  const chimeBearerArn = createMemberArn(userId);

  const params = {
    ChimeBearer: chimeBearerArn,
  };

  const request = (
    await chimeMessagingClient()
  ).listChannelMembershipsForAppInstanceUser(params);
  const response = await request.promise();
  const channels = response.ChannelMemberships;
  return channels;
}

async function listChannels(appInstanceArn, userId) {
  console.log("listChannels called");

  const chimeBearerArn = createMemberArn(userId);
  const params = {
    AppInstanceArn: appInstanceArn,
    ChimeBearer: chimeBearerArn,
  };

  const request = (await chimeMessagingClient()).listChannels(params);
  const response = await request.promise();
  const channels = response.Channels;
  return channels;
}

async function listChannelsForAppInstanceUser(userId) {
  console.log("listChannelsForAppInstanceUser called");

  const chimeBearerArn = createMemberArn(userId);
  const params = {
    ChimeBearer: chimeBearerArn,
  };
  const request = (await chimeMessagingClient()).listChannelsForAppInstanceUser(
    params
  );
  const response = await request.promise();
  const channels = response.Channels;
  console.log("channels", channels);
  return channels;
}

async function deleteChannel(channelArn, userId) {
  console.log("deleteChannel called");

  const chimeBearerArn = createMemberArn(userId);
  const params = {
    ChannelArn: channelArn,
    ChimeBearer: chimeBearerArn,
  };

  const request = (await chimeMessagingClient()).deleteChannel(params);
  await request.promise();
}

async function listChannelModerators(channelArn, userId) {
  console.log("listChannelModerators called");
  const chimeBearerArn = createMemberArn(userId);

  const params = {
    ChannelArn: channelArn,
    ChimeBearer: chimeBearerArn,
  };

  const request = (await chimeMessagingClient()).listChannelModerators(params);
  const response = await request.promise();
  return response ? response.ChannelModerators : null;
}

async function updateChannelMessage(
  channelArn,
  messageId,
  content,
  metadata,
  userId
) {
  console.log("updateChannelMessage called");
  const chimeBearer = createMemberArn(userId);
  const params = {
    ChannelArn: channelArn,
    MessageId: messageId,
    Content: content,
    Metadata: metadata,
    ChimeBearer: chimeBearer,
  };

  const request = (await chimeMessagingClient()).updateChannelMessage(params);

  const response = await request.promise();
  return response;
}

async function redactChannelMessage(channelArn, messageId, userId) {
  console.log("redactChannelMessage called");

  const chimeBearerArn = createMemberArn(userId);
  const params = {
    ChannelArn: channelArn,
    MessageId: messageId,
    ChimeBearer: chimeBearerArn,
  };

  const request = (await chimeMessagingClient()).redactChannelMessage(params);

  const response = await request.promise();
  console.log("response", response);
  return response;
}

export {
  sendChannelMessage,
  listChannelMessages,
  createChannelMembership,
  listChannelMemberships,
  deleteChannelMembership,
  createChannelBan,
  deleteChannelBan,
  listChannelBans,
  createChannel,
  describeChannel,
  updateChannel,
  listChannels,
  listChannelsForAppInstanceUser,
  deleteChannel,
  listChannelModerators,
  updateChannelMessage,
  redactChannelMessage,
  getMessagingSessionEndpoint,
  listChannelMembershipsForAppInstanceUser,
  listAppInstanceUsers,
};
