/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { createContext, useContext, useState } from "react";
import { useNotificationDispatch } from "amazon-chime-sdk-component-library-react";
import AWS from "aws-sdk";
import { useAppState } from "./AppStateProvider";
import { getCreds } from "../utils/api";

const AuthContext = createContext();
const AuthProvider = ({ children }) => {
  const notificationDispatch = useNotificationDispatch();
  // Member
  const [member, setMember] = useState({
    username: "",
    userId: "",
  });
  // Auth state
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // isAnonymous (anon does not have access to write to S3 for attachments) default to cognito flow
  const [isAnonymous, setAnonymous] = useState(false);
  // Using CognitoIdp (if true use Cognito IDP to search for users when adding members to a room,
  // else lookup using ListAppInstanceUsers API), default to Cognito flow
  const [useCognitoIdp, setUseCognitoIdp] = useState(false);
  let expirationDate = null;

  const { meetingId, token, setChatCredentials } = useAppState();

  const setAuthenticatedUserFromCredentialExchangeService = (response) => {
    setUseCognitoIdp(false);
    setAnonymous(true);
    setMember({
      username: response.ChimeDisplayName,
      userId: response.ChimeUserId,
    });

    setIsAuthenticated(true);
  };

  // Credential Exchange Service Code.  Set Access Token on Authorization header using Bearer type.
  const userExchangeTokenForAwsCreds = (creds) => {
    setAuthenticatedUserFromCredentialExchangeService(creds);
  };

  const authFulfiller = {
    AWS,
    member,
    isAuthenticated,
    isAnonymous,
    useCognitoIdp,
    userExchangeTokenForAwsCreds: userExchangeTokenForAwsCreds,
  };

  return (
    <AuthContext.Provider value={authFulfiller}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuthContext must be used within AuthProvider");
  }

  return context;
};

export { AuthProvider, useAuthContext };
