// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import styled from "styled-components";

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 96px;
  gap: 64px;
  background: #fcfcfd;

  @media screen and (max-width: 1024px) {
    padding: 0;
  }
`;

export const StyledLayout = styled.main`
  /* max-height: 80vh; */
  width: 100%;
  background: #fcfcfd;

  display: grid;

  .video-content {
    grid-area: content;
  }

  ${({ showRoster, showChat }) => {
    if (showRoster || showChat) {
      return `
        grid-template-columns: auto 1fr;
        grid-template-areas: 'roster content';
      `;
    }

    return `
      grid-template-columns: 1fr;
      grid-template-areas: 'content';
    `;
  }}

  .nav {
    grid-area: nav;
  }

  .roster {
    grid-area: roster;
    z-index: 2;
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 16px 32px 24px;
    gap: 40px;
    /* Base / White */
    background: #ffffff;
    /* Gray / 200 */
    border: 1px solid #e3e8ef;
    border-radius: 12px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
  .rosterHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
  .rosterHeaderText {
    /* Text/lg/Semibold */
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height, or 156% */
    text-align: center;
    /* Gray/900 */
    color: #101828;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 1;
  }
  .closeRosterIcon {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 3px;
    border-radius: 3px;
    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;
  }

  @media screen and (min-width: 769px) {
    .mobile-toggle {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-areas: "content";
    padding-bottom: 45px;

    .roster {
      grid-area: unset;
      position: absolute;
      top: 11vh;
      left: 0;
      right: 0;
      bottom: 0;
      max-width: 320px;
    }
  }
`;

export const StyledContent = styled.div`
  position: relative;
  grid-area: content;
  height: 100%;
  display: flex;
  min-height: 95vh;
  // flex-direction: column;

  .videoGrid {
    position: relative;

    display: flex;
    flex-direction: row;
    border: 1px solid #e3e8ef;
    border-radius: 12px;
    background: #697586;
    /* Gray / 200 */

    @media screen and (max-width: 1024px) {
      border-radius: 0px;
    }
  }
`;

// export const VideoItem = styled.div`
//   position: relative;
//   flex: 1;

//   button {
//     position: absolute;
//     top: 14px;
//     right: 14px;
//     z-index: 1;
//     padding: 0.25rem 0.5rem !important;
//   }
// `;

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 96px;
  gap: 24px;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 24px;
  }
  .text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    padding-right: 5px;
    /* identical to box height, or 127% */

    text-align: center;

    /* Primary / 600 */

    color: #ff692e;
  }
  .text-1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    /* identical to box height, or 127% */

    text-align: center;

    /* Primary / 600 */

    color: #101828;
  }
  .pageHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 20px;
  }
  .content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 16px;
  }
  .supportText {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 4px;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
