// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import styled from "styled-components";

export const StyledControls = styled.div`
  display: flex;
  justify-content: center;
  flex: none;

  .control-bar {
    // position: unset;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    // background: transparent;
  }

  .controls {
    position: absolute;
    bottom: 40px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    bottom: 40px;
    width: 720px;
    left: 50%;
    margin-left: -360px;
    background: rgba(255, 255, 255, 0.85);
    border-radius: 120px;
    padding: 20px 40px;
  }

  .controls .ch-control-bar-item-label {
    text-align: center;
  }

  @media screen and (max-width: 1024px) {
    .meetingDetail {
      //display: none;
    }
    .controls {
      margin-left: initial !important;
      bottom: 0;
      justify-content: space-around;
      z-index: 9999;
      width: 100% !important;
      padding: 6px;
      border-radius: 0px;

      gap: 0px;
      justify-content: space-between;
      left: 0 !important;
      right: 0 !important;
    }
    .controls > div {
      margin: 0 !important;
      flex: 1;
    }
    .toggle-roster {
      display: block;
    }
    .toggle-chat {
      display: block;
    }
    .controls-menu {
      padding-right: 6px;
    }
  }
`;
