import React, { useEffect } from 'react'
import { useLocalVideo } from 'amazon-chime-sdk-component-library-react'
import { StopButton, StartButton } from './Styled'
import { useAppState } from '../../providers/AppStateProvider'
import { ATTENDEE_ROLE, COURSE_TYPE } from '../../constants'

const VideoInputControlButton = () => {
  const { isVideoEnabled, toggleVideo, setIsVideoEnabled } = useLocalVideo()
  const { role, courseType } = useAppState()
  const isAllowed = true

  useEffect(() => {
    if (!isAllowed) {
      setIsVideoEnabled(false)
    }
  }, [])

  const StopYourVideoIcon = () => {
    return (
      <StopButton>
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="55"
            height="55"
            rx="27.5"
            fill="#E3E8EF"
          />
          <path
            d="M19.8335 19.8334C17.9005 19.8334 16.3335 21.4004 16.3335 23.3334V32.6667C16.3335 34.5997 17.9005 36.1667 19.8335 36.1667H30.3335C31.9116 36.1667 33.2458 35.1223 33.6825 33.6869M33.8335 28L38.0735 23.76C38.5733 23.2602 38.8232 23.0103 39.0377 22.9935C39.2239 22.9788 39.4058 23.0542 39.5271 23.1962C39.6668 23.3598 39.6668 23.7132 39.6668 24.42V31.5801C39.6668 32.2869 39.6668 32.6403 39.5271 32.8039C39.4058 32.9459 39.2239 33.0213 39.0377 33.0066C38.8232 32.9897 38.5733 32.7398 38.0735 32.2401L33.8335 28ZM33.8335 28V25.4334C33.8335 23.4732 33.8335 22.4931 33.452 21.7444C33.1165 21.0858 32.581 20.5504 31.9225 20.2149C31.1738 19.8334 30.1937 19.8334 28.2335 19.8334H25.0835M16.3335 16.3334L39.6668 39.6667"
            stroke="#9AA4B2"
            stroke-width="2.33333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <rect
            x="0.5"
            y="0.5"
            width="55"
            height="55"
            rx="27.5"
            stroke="#CDD5DF"
          />
        </svg>
        <div className="StopButtonLabel">Camera off</div>
      </StopButton>
    )
  }

  const OpenYourVideoIcon = () => {
    return (
      <StartButton>
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="55"
            height="55"
            rx="27.5"
            fill="#FCFCFD"
          />
          <path
            d="M39.6663 24.4199C39.6663 23.7131 39.6663 23.3597 39.5266 23.1961C39.4053 23.0541 39.2234 22.9788 39.0372 22.9934C38.8227 23.0103 38.5728 23.2602 38.073 23.7599L33.833 28L38.073 32.24C38.5728 32.7398 38.8227 32.9897 39.0372 33.0066C39.2234 33.0212 39.4053 32.9459 39.5266 32.8039C39.6663 32.6402 39.6663 32.2868 39.6663 31.58V24.4199Z"
            stroke="#697586"
            stroke-width="2.33333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.333 25.4333C16.333 23.4731 16.333 22.493 16.7145 21.7443C17.05 21.0858 17.5855 20.5503 18.244 20.2148C18.9927 19.8333 19.9728 19.8333 21.933 19.8333H28.233C30.1932 19.8333 31.1733 19.8333 31.922 20.2148C32.5805 20.5503 33.116 21.0858 33.4515 21.7443C33.833 22.493 33.833 23.4731 33.833 25.4333V30.5666C33.833 32.5268 33.833 33.5069 33.4515 34.2556C33.116 34.9142 32.5805 35.4496 31.922 35.7852C31.1733 36.1666 30.1932 36.1666 28.233 36.1666H21.933C19.9728 36.1666 18.9927 36.1666 18.244 35.7852C17.5855 35.4496 17.05 34.9142 16.7145 34.2556C16.333 33.5069 16.333 32.5268 16.333 30.5666V25.4333Z"
            stroke="#697586"
            stroke-width="2.33333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <rect
            x="0.5"
            y="0.5"
            width="55"
            height="55"
            rx="27.5"
            stroke="#CDD5DF"
          />
        </svg>
        <div className="StartButtonLabel">Camera on</div>
      </StartButton>
    )
  }

  return (
    <div
      onClick={() => {
        if (isAllowed) toggleVideo()
      }}
    >
      {isVideoEnabled && isAllowed ? (
        <OpenYourVideoIcon />
      ) : (
        <StopYourVideoIcon />
      )}
    </div>
  )
}

export default VideoInputControlButton
