/* eslint-disable react/no-children-prop */
/* eslint-disable no-console */
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import {
  ChatBubble,
  ChatBubbleContainer,
  EditableChatBubble,
  formatDate,
  formatTime,
  InfiniteList,
  Modal,
  ModalBody,
  ModalButton,
  ModalButtonGroup,
  ModalHeader,
  PopOverItem,
  useRosterState,
} from 'amazon-chime-sdk-component-library-react'
import React, { useEffect, useRef, useState } from 'react'
import { useAppState } from '../../providers/AppStateProvider'
import { useAuthContext } from '../../providers/AuthProvider'
import {
  useChatChannelState,
  useChatMessagingState,
} from '../../providers/ChatMessagesProvider'
import {
  createMemberArn,
  listChannelMessages,
  redactChannelMessage,
  updateChannelMessage,
} from '../../utils/chime'
import insertDateHeaders from '../../utils/insertDateHeaders'
import { AttachmentProcessor } from './AttachmentProcessor'
import Input from './Input'
import './Messages.css'
import { ReactComponent as XIcon } from '../../assets/images/icons/x-close.svg'
import { useNavigation } from '../../providers/NavigationProvider'

const Messages = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { messages, messagesRef, setMessages } = useChatMessagingState()
  const { toggleChat } = useNavigation()

  const {
    setChannelMessageToken,
    setActiveChannel,
    activeChannel,
    activeChannelRef,
  } = useChatChannelState()
  const { chatCredentials } = useAppState()
  const { channelMessageTokenRef } = useChatChannelState()
  const { member } = useAuthContext()
  const { roster } = useRosterState()

  const { userId } = member
  useEffect(() => {
    async function initial() {
      if (chatCredentials && !channelMessageTokenRef.current) {
        setIsLoading(true)
        try {
          const oldMessages = await listChannelMessages(
            chatCredentials.ChannelArn,
            userId
          )
          setMessages(oldMessages.Messages)
          setChannelMessageToken(oldMessages.NextToken)
        } catch (ex) {
          console.error('FAILED initial', ex)
        } finally {
          setIsLoading(false)
        }
      }
    }
    initial()
  }, [chatCredentials])

  const handleScrollTop = async () => {
    setIsLoading(true)
    if (!channelMessageTokenRef.current) {
      console.log('No new messages')
      setIsLoading(false)
      return
    }
    const oldMessages = await listChannelMessages(
      chatCredentials.ChannelArn,
      userId,
      channelMessageTokenRef.current
    )
    const newMessages = [...oldMessages.Messages, ...messagesRef.current]

    setMessages(newMessages)
    setChannelMessageToken(oldMessages.NextToken)
    setIsLoading(false)
  }

  const [showDiscardModal, setShowDiscardModal] = useState(false)
  const [showRedactModal, setShowRedactModal] = useState(false)
  const [editingMessageId, setEditingMessageId] = useState('')
  const [redactingMessageId, setRedactingMessageId] = useState('')

  const handleDiscardEdit = () => {
    setShowDiscardModal(false)
    setEditingMessageId('')
  }

  const discardModal = (
    <Modal onClose={() => setShowDiscardModal(false)}>
      <ModalHeader title="Discard Changes?" />
      <ModalBody>
        <div>You cannot undo this action.</div>
        <ModalButtonGroup
          primaryButtons={[
            <ModalButton
              label="Discard"
              type="submit"
              variant="primary"
              onClick={handleDiscardEdit}
              key="1"
            />,
            <ModalButton
              label="Cancel"
              variant="secondary"
              closesModal
              key="2"
            />,
          ]}
        />
      </ModalBody>
    </Modal>
  )

  const handleShowRedactModal = (messageId) => {
    setRedactingMessageId(messageId)
    setShowRedactModal(true)
  }

  const handleCloseRedactModal = () => {
    setRedactingMessageId('')
    setShowRedactModal(false)
  }

  const redact = async () => {
    await redactChannelMessage(
      chatCredentials.ChannelArn,
      redactingMessageId,
      userId
    )
    setShowRedactModal(false)
  }

  const redactModal = (
    <Modal onClose={handleCloseRedactModal}>
      <ModalHeader title="Delete Message?" />
      <ModalBody>
        <div>You cannot undo this action.</div>
        <ModalButtonGroup
          primaryButtons={[
            <ModalButton
              label="Delete"
              type="submit"
              variant="primary"
              onClick={redact}
              key="1"
            />,
            <ModalButton
              label="Cancel"
              variant="secondary"
              closesModal
              key="2"
            />,
          ]}
        />
      </ModalBody>
    </Modal>
  )

  const cancelEdit = (e) => {
    e.preventDefault()
    setShowDiscardModal(true)
  }

  const saveEdit = async (e, newText, metadata) => {
    e.preventDefault()
    await updateChannelMessage(
      chatCredentials.ChannelArn,
      editingMessageId,
      newText,
      metadata,
      userId
    )
    setEditingMessageId('')
  }

  const flattenedMessages = messages.map((m) => {
    const content = !m.Content || m.Redacted ? '(Deleted)' : m.Content
    let editedNote
    if (m.LastEditedTimestamp && !m.Redacted) {
      const time = formatTime(m.LastEditedTimestamp)
      const date = formatDate(
        m.LastEditedTimestamp,
        undefined,
        undefined,
        'today',
        'yesterday'
      )
      editedNote = (
        <i style={{ fontStyle: 'italic' }}>{` (edited ${date} at ${time})`}</i>
      )
    }
    return {
      content: content,
      editedNote: editedNote,
      messageId: m.MessageId,
      createdTimestamp: m.CreatedTimestamp,
      redacted: m.Redacted,
      senderName: m.Sender.Name,
      senderId: m.Sender.Arn,
      senderArn: m.Sender.Arn,
      metadata: m.Metadata,
      avatar: Object.values(roster).find(
        (rosterUser) => rosterUser.userArn === m.Sender.Arn
      )?.avatar,
    }
  })
  const listItems = insertDateHeaders(flattenedMessages)

  const messageList = listItems.map((m, i, self) => {
    if (!m.content) {
      return m
    }

    const variant =
      createMemberArn(userId) === m.senderId ? 'outgoing' : 'incoming'
    let actions = null

    if (variant === 'outgoing') {
      actions = [
        <PopOverItem
          key="1"
          children={<span>Edit</span>}
          onClick={() => setEditingMessageId(m.messageId)}
        />,
        <PopOverItem
          key="2"
          children={<span>Delete</span>}
          onClick={() => handleShowRedactModal(m.messageId)}
        />,
      ]
    }

    const prevMessageSender = self[i - 1]?.senderId
    const currMessageSender = m.senderId
    const nextMessageSender = self[i + 1]?.senderId

    let showTail = true
    if (
      currMessageSender && // it is a message
      nextMessageSender && // the item after is a message
      currMessageSender === nextMessageSender // the item after is from the same sender
    ) {
      showTail = false
    }
    let showName = true
    if (
      currMessageSender && // it is a message
      prevMessageSender && // the item before is a message
      currMessageSender === prevMessageSender // the message before is from the same sender
    ) {
      showName = false
    }

    const attachment = (metadata) => {
      try {
        const metadataJSON = JSON.parse(metadata)
        return metadataJSON?.attachments[0]
      } catch (err) {
        // not an json object! ignoring
      }
      return false
    }

    return (
      <div className="py-2">
        <ChatBubbleContainer
          // timestamp={<>{formatTime(m.createdTimestamp)}</>}
          // actions={actions}
          key={`message${i.toString()}`}
          className="!bg-transparent"
        >
          {editingMessageId === m.messageId && !m.redacted ? (
            <EditableChatBubble
              variant={variant}
              senderName={
                <div className="text-sm text-dgray-700 font-medium text-left mb-1.5">
                  {m.senderName}
                </div>
              }
              content={m.content}
              save={(event, value) => saveEdit(event, value, m.metadata)}
              cancel={cancelEdit}
              showName={showName}
              className="chat-bubble"
            />
          ) : variant === 'outgoing' ? (
            <ChatBubbleOutgoing
              m={m}
              showName={showName}
              attachment={attachment}
            />
          ) : (
            <ChatBubbleIncoming
              m={m}
              showName={showName}
              attachment={attachment}
            />
          )}
        </ChatBubbleContainer>
      </div>
    )
  })

  // preventing the whole window from scrolling to the bottom
  const chatContainerRef = useRef()
  useEffect(() => {
    if (chatContainerRef.current) {
      const observerDiv = chatContainerRef.current.querySelector(
        '#chat-infinite-list'
      ).lastChild
      if (observerDiv) observerDiv.scrollIntoView = () => {}
    }
  }, [chatContainerRef, chatContainerRef.current])

  return (
    <div
      className="message-list-container ml-10 md:ml-0"
      ref={chatContainerRef}
    >
      {showDiscardModal && discardModal}
      {showRedactModal && redactModal}
      <div className="py-8 pl-6 pr-4 flex justify-center">
        <div className="flex-1 text-lg text-dgray-900 font-semibold">
          Event Chat
        </div>
        <button onClick={toggleChat}>
          <XIcon className="text-dgray-400" />
        </button>
      </div>
      <InfiniteList
        id="chat-infinite-list"
        style={{
          display: 'flex',
          flexGrow: '1',
          maxHeight: '100%',
          background: 'transparent',
          margin: '0 16px 0 24px',
          paddingRight: '32px',
        }}
        items={messageList}
        onLoad={handleScrollTop}
        isLoading={isLoading}
      />
      <Input
        activeChannelArn={chatCredentials?.ChannelArn}
        member={member}
        hasMembership={true}
      />
    </div>
  )
}
export default Messages

function ChatBubbleIncoming({ m, showName, attachment }) {
  return (
    <ChatBubble
      variant={'incoming'}
      senderName={
        <div className="flex justify-between">
          <div className="text-sm text-dgray-700 font-medium text-left mb-1.5">
            {m.senderName}
          </div>
          <div className="text-xs text-dgray-500 font-normal">
            {formatTime(m.createdTimestamp)}
          </div>
        </div>
      }
      redacted={m.redacted}
      showName={showName}
      className="chat-bubble"
    >
      <div className="flex gap-3">
        <IncomingAvatar m={m} />
        <div className="text-left text-base rounded-lg py-2.5 px-3.5 bg-dgray-100 rounded-tl-none">
          {m.content}
        </div>
      </div>
      <span className="text-2xs text-dgray-500">{m.editedNote}</span>
      {m.metadata && (
        <div style={{ marginTop: '10px' }}>
          <AttachmentProcessor
            senderId={m.senderId}
            {...attachment(m.metadata)}
          />
        </div>
      )}
    </ChatBubble>
  )
}

function IncomingAvatar({ m }) {
  return (
    <div className="h-8 w-8 rounded-full overflow-hidden">
      {m.avatar ? (
        <img src={process.env.REACT_APP_MEDIA_URL + m.avatar} alt="avatar" />
      ) : (
        <div className="h-full w-full text-dgray-700 font-medium bg-primary-100 flex items-center justify-center whitespace-nowrap">
          {getInitials(m.senderName)}
        </div>
      )}
    </div>
  )
}

function getInitials(name) {
  if (!name) return ''

  return name
    .split(' ')
    .map((n) => n[0])
    .join('')
}

function ChatBubbleOutgoing({ m, showName, attachment }) {
  return (
    <ChatBubble
      variant={'outgoing'}
      senderName={
        <div className="flex justify-between">
          <div className="text-sm text-dgray-700 font-medium text-left mb-1.5">
            You
          </div>
          <div className="text-xs text-dgray-500 font-normal">
            {formatTime(m.createdTimestamp)}
          </div>
        </div>
      }
      redacted={m.redacted}
      showName={showName}
      className="chat-bubble chat-bubble--outgoing"
    >
      <div className="text-left text-base rounded-lg py-2.5 px-3.5 bg-primary-600 text-white rounded-tr-none">
        {m.content}
      </div>
      <span className="text-2xs text-dgray-500">{m.editedNote}</span>
      {m.metadata && (
        <div style={{ marginTop: '10px' }}>
          <AttachmentProcessor
            senderId={m.senderId}
            {...attachment(m.metadata)}
          />
        </div>
      )}
    </ChatBubble>
  )
}
