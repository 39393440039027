// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import styled from "styled-components";

export const AttendeesSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px 0px 0px 40px;
  gap: 64px;
  height: 100%;
  .MicrophoneActivity {
    width: 24px;
    height: 24px;
  }

  /* Inside auto layout */
  @media screen and (max-width: 1024px) {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 10000;
    justify-content: stretch;
    padding: 0px;
    height: 70%;
  }

  .attendeesContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    width: 100%;
  }
  .attendeesList {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 16px 32px 24px;
    gap: 40px;
    /* Base / White */
    background: #ffffff;
    /* Gray / 200 */
    border: 1px solid #e3e8ef;
    border-radius: 12px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    width: 100%;
  }
  .attendeesHeading {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
  .attendeesHeadingText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height, or 156% */
    text-align: center;
    /* Gray/900 */
    color: #101828;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 1;
  }
  .attendeesHeadingIcon-1 {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    padding: 3px;
    border-radius: 3px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .attendeesHeadingIcon-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 3px;
    border-radius: 3px;
    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;
  }

  .attendeesMain {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    width: 320px;
    // height: 860px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
  }
  .attendeesMain-1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 1;
  }
  .audience {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 16px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
  .eventHosts-1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
  .audience-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
  .audience-header-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    text-align: center;
    /* Gray / 500 */
    color: #697586;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .audience-header-divider {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: none;
    order: 1;
    flex-grow: 1;
  }
  .audience-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 60px;
    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }
  .audience-main-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 60px;
    width: 100%;
  }
  .avatar-label-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    width: 100%;
  }
  .avatar-label-group-1 {
    width: 32px;
    height: 32px;
    /* Avatar user square/Olivia Rhye */
    background: white;
    /* Primary/100 */
    border: 4px solid #ffe6d5;
    border-radius: 200px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .avatar-label-group-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    width: 100%;
  }
  .avatar-label-group-2-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    /* Gray/700 */
    color: #344054;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    width: 100%;
  }
  .microphoneIcon {
    flex: none;
    order: 1;
    flex-grow: 0;
  }
`;
